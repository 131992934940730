<template>
  <header>
      <div class="content">
        <router-link class="logo" v-if="this.$store.state.oid!=72"  :to="'/home/'+this.$store.state.oid"><img :src="this.$store.state.baseHost+'/fs/download?storeKey='+$store.state.orgLogo" :onerror="defaultLogoImg"/></router-link>
        <router-link class="logo" v-if="this.$store.state.oid==72"  :to="'/zg/login/'+this.$store.state.oid"><img :src="this.$store.state.baseHost+'/fs/download?storeKey='+$store.state.orgLogo" :onerror="defaultLogoImg"/></router-link>
            <ul class="tabs">
                <template v-for="(item,index) in navbarData">
                    <li v-bind:key="index" v-bind:class="item.aclass" v-if="item.show"><router-link :to="item.link">{{item.name}}</router-link></li>
                </template>
            </ul>
        <div class="btns navbar-right" v-if="!this.$store.state.userInfo">
            <span v-if="this.$store.state.oid!=72 && this.$store.state.oid!=110"><router-link :to="'/ft/login/'+this.$store.state.oid" class="logbtn">登錄/注冊</router-link></span>
        </div>
        <div class="navbar-right" v-else>
            <li class="dropdown">
            <div class="adm-avatar"><a href="#"><img id="loginPhoto" :src="this.$store.state.userInfo.memberPhotourl?this.$store.state.baseHost+'/fs/download?storeKey='+this.$store.state.userInfo.memberPhotourl:require('../assets/photo0.png')"></a></div>
            <div class="adm-link"><a href="javascript:void(0)">{{this.$store.state.userInfo.memberName}}</a></div>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                <li id="updatePwdBtn"><a href="javascript:void(0)" @click="pwdVisible = true"><img src="../assets/lock.png" ><span>修改密碼</span></a></li>
                <li id="uc"><a href="javascript:void(0)" @click="goCenter"><img src="../assets/ucicon.png" ><span>個人中心</span></a></li>
                <li id="lo"><a href="javascript:void(0)" @click="logout()"><img src="../assets/out.png" ><span>退出登錄</span></a></li>
                </ul>
            </li>
        </div>
    </div>
     <el-dialog title="修改密碼" width="35%" :visible.sync="pwdVisible" :close-on-click-modal="false" :modal-append-to-body='false' center>
        <el-form :model="form.formData" :rules="form.formRules" ref="form"  label-width="37%">
            <div class="col-lg-offset-2">
                <div class="profile-row">
                    <el-form-item label="當前密碼" prop="oldPassword">
                        <el-input v-model="form.formData.oldPassword" show-password type="password" style="width:auto" autocomplete="off" placeholder="請輸入當前密碼" clearable></el-input>
                    </el-form-item>
                </div>
                <div class="profile-row">
                    <el-form-item label="新密碼：" prop="newPassword">
                        <el-input v-model="form.formData.newPassword" show-password type="password" style="width:auto" autocomplete="off" placeholder="請輸入新密碼" clearable></el-input>
                    </el-form-item>
                </div>
                <div class="profile-row">
                    <el-form-item label="確認密碼：" prop="newPassword2">
                        <el-input v-model="form.formData.newPassword2" show-password type="password" style="width:auto" autocomplete="off" placeholder="請在次輸入密碼" clearable></el-input>
                    </el-form-item>
                </div>
            </div>
        </el-form>
        <div slot="footer" class="dialog-footer" style="margin-top: 5px;">
            <el-button @click="cancelPWD('form')">取消</el-button>
            <el-button type="primary" @click="changePWD()">保存</el-button>
        </div>
    </el-dialog>
  </header>
</template>

<script>
import Vue from 'vue'

export default {
    name: "index",
    props:{
        currentId: {
            default: 0,

        },
    },
    data(){
        return{
            // orgLogo: null,
            pwdVisible: false,
            defaultLogoImg: 'this.src="' + require("../assets/logo.png") + '"',
            form:{
				formData:{
					oldPassword: '',//当前密码
					newPassword: '',//新密码
					newPassword2: '',//确认密码
				},
				formRules:{
					oldPassword: [
						{ required: true, message: '當前密碼不能為空', trigger: 'blur' },
                    	{ min: 6, max: 36, message: '長度在 6 到 36 個字元', trigger: 'blur' }
                    ],
                    newPassword: [
						{ required: true, message: '新密碼不能為空', trigger: 'blur' },
                    	{ min: 6, max: 36, message: '長度在 6 到 36 個字元', trigger: 'blur' }
                    ],
                    newPassword2: [
                        { required: true, message: '確認密碼不能為空', trigger: 'blur' },
                    	{ min: 6, max: 36, message: '長度在 6 到 36 個字元', trigger: 'blur' }
                    ],
				}
			},
            navbarData:[
                // {
                //     name: '會計專業',
                //     link: '/home/'+this.$store.state.oid,
                //     id: 0,
                //     show: true,
                // },
                // {
                //     name: '旅游專業',
                //     link: '/kechengguangchang',
                //     id: 5,
                //     show: true,
                // },
                // {
                //     name: '農學專業',
                //     link: '/dasaijieshao',
                //     id: 1,
                //     show: true,
                // },
                // {
                //     name: '生物科技專業',
                //     link: '/zaixianbaoming',
                //     id: 2,
                //     show: true,
                // }
            ]
        };
    },
    methods: {
        logout(){
            console.log('进来了');
			this.$confirm('是否退出登錄?', '提示', {
                confirmButtonText: '是',
                cancelButtonText: '否',
                type: 'warning'
            }).then(() => {
            //点击确定的操作(调用接口)
            this.$post("/api/appuser/logout").then((res) => {
					//console.log(res);
					if (res.result) {
						this.$store.commit("LOG_OUT");
                        sessionStorage.clear();
                        this.$router.push("/ft/login/"+this.$store.state.oid);
                        
					}
				});
            }).catch(() => {
            //点取消的提示
                return;
            });
        },
        changePWD(){
			if(this.form.formData.newPassword!=this.form.formData.newPassword2){
				this.$message.error('兩次輸入的新密碼不一致，請檢查');
				return
			}
			this.$post('/api/appuser/updatePassword',
			{
				oldPassword:this.form.formData.oldPassword,
				newPassword:this.form.formData.newPassword
			}).then((res)=>{
				console.log(res);
				if(res.result){
					this.$message.success(res.resultMsg);
					//这里执行登出
					this.$post("/api/appuser/logout").then((res) => {
						console.log(res);
						if (res.result) {
							this.$store.commit("LOG_OUT");
                            this.$router.push("/ft/login/"+this.$store.state.oid);
						}
					});
				}else{
					this.$message.error(res.resultMsg);
				}
			}).catch((res)=>{
				console.log(res);
			})
        },
        cancelPWD(formName){
            this.$refs[formName].resetFields();
            this.pwdVisible = false;
        },
        goCenter(){
            if(this.$store.state.userInfo.student){
                this.$router.push({name: 'ft_student_profile'});
            }else{
                if(this.$store.state.oid == 72){//江苏
                   this.$router.push({name: 'jspingshen_index'});
                }else if(this.$store.state.oid == 110){//广州市职业技术教育研究院
                   this.$router.push({name: 'gzzjpingshen_index'});
                }else{
                    this.$router.push({name: 'pingshen_index'});
                }
            }
        },
        /**
         * 获取专业
         */
        async getCourseCategory(){
            try {
                const res = await this.$post("/website/listOrgCategory",{
                    category: 'COURSE_MAJOR',
                    orgId: this.$store.state.oid
                })
                if(res.resultData.length>0){
                    this.navbarData = [];
                }
                res.resultData.forEach(item=>{
                    
                    if(this.$store.state.kcMajor==''){
                        this.$store.commit('updateMajor', this.$route.params.code==null ? item.dictCode:this.$route.params.code);
                    }else if(this.$route.params.code!=null && (this.$store.state.kcMajor!=this.$route.params.code)){
                        this.$store.commit('updateMajor', this.$route.params.code);
                    }
                    this.navbarData.push({
                        name: item.dictValue,
                        link: '/kechengguangchangft/'+item.dictCode,
                        id: item.dictCode,
                        show: true,
                        aclass: this.$store.state.kcMajor==item.dictCode ? 'active':'',
                    })
                    
                })
            } catch(err){

            }
        }
    },
    created () {
    },
    mounted() {
        this.getCourseCategory()
    },
    watch:{
        $route(to, from) {
            if (to.params.code !== from.params.code) {
                this.$store.commit('updateMajor', to.params.code);
                this.getCourseCategory();
            }
        }
    }
}
</script>

<style scoped>
/* 头部样式 */
header{width: 100%;min-width: 1200px;height: 59px;background: #fff;box-shadow: 0px 3px 7px 0px rgba( 0, 0, 0 ,0.1);padding: 15px 0;}
header .content{width: 1200px;height: 59px;margin: 0 auto;display: flex;justify-content: space-between;}
header .content .logo{padding:5px;max-width: 460px;overflow: hidden;box-sizing: content-box;}
header .content .logo img{height: 100%;}
header .content .tabs{width:auto;height: 30px;display: flex;margin-top: 15px;flex:1;justify-content: flex-end;margin-left: 30px;}
header .content .tabs li{float:left;text-align:center;width: auto;cursor: pointer;margin-right: 40px;color:#222222;font-family: 'Antic', sans-serif;font-size:18px;}
header .content .tabs li a{color: #222;display: block;height: 100%;}
header .content .tabs li.active{border-bottom:2px solid #f79238;color: #f79238;}
header .content .tabs li:hover{border-bottom:2px solid #f79238;color: #f79238;}
header .content .tabs li:hover a{color: #f79238;}
header .content .tabs li.active a{color: #f79238;}
header .content .btns a.logbtn{width: auto;height: 30px;line-height:30px;border:1px solid #5786c7;border-radius: 4px;margin-left: 15px;margin-top: 14px;color: #5786c7;cursor: pointer;display: inline-block;text-align:center;padding: 0 10px;}

.dropdown{
    height: 58px;
    display: flex;
    align-items: center;
    position: relative;
}
.adm-avatar{
	width: 40px;
	/* float: left; */
}
.adm-avatar img{
	width: 40px;
	height: 40px;
	border-radius: 40px;
    display: block;
}
.adm-link{
	color: #038eeb;
	text-align: center;
	line-height: 1;
	margin-left: 10px;
}
.adm-link a{
	color: #038eeb;
}
.admin-nav{
	padding: 5px 0;
}
.admin-nav>li{
	padding: 15px 0;
}
.navbar-right .dropdown-menu {
    top: 98%;
    right: -15px;
    width: 130px;
    position: absolute;
    z-index: 1000;
    float: left;
    min-width: 100px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    display: none;
}
.dropdown-menu li a {
    padding: 10px 0;
    text-align: center;
    line-height: 1;
}

.dropdown-menu>li>a {
    display: block;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}
.dropdown-menu li a img {
    margin-right: 5px;
}
.dropdown-menu li a * {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
}
.dropdown:hover .dropdown-menu{
    display: block;
}
</style>