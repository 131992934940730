import { render, staticRenderFns } from "./PwingNew.vue?vue&type=template&id=267ff69e&scoped=true"
import script from "./PwingNew.vue?vue&type=script&lang=js"
export * from "./PwingNew.vue?vue&type=script&lang=js"
import style0 from "./PwingNew.vue?vue&type=style&index=0&id=267ff69e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "267ff69e",
  null
  
)

export default component.exports