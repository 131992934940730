<template>
  <div class="main-body" v-if="stageliveBgUrl != null && stageliveBgUrl != ''"
    :style="{ 'background-image': 'url(' + stageliveBgUrl + ')' }">
    <!--<div class="main-body">-->
    <div class="main">
      <div class="title-box" id="titleBox" v-if="partNumber != 0 && voiceTest == 0">
        <div class="huanjie" v-if="projData.stageliveShowName == 1">{{ projData.contestName }}</div>
        <!-- <div class="huanjie" v-if="projData.stageName"></div>{{projData.stageName}}-->
        <!-- <template v-if="projData.contprojList"> -->
        <div class="project-name" id="contproj">
          <span class="city" v-if="stageliveShowContent == 0">{{ nowProj.sortnum<10?"0"+nowProj.sortnum:nowProj.sortnum}}</span>
          <span class="city" v-if="stageliveShowContent == 1">{{ cityHtml }}</span>
          <!-- <span class="city" v-if="cityHtml">{{cityHtml}}</span> -->
          <template v-if="contprojName">
            {{ contprojName }}
          </template>
        </div>
        <!-- </template> -->
      </div>
      <div class="content zcdd" v-if="partNumber == 0 && voiceTest == 0">
        <div class="zcdd-title" :style="{ 'color': stageliveFontColor }">转场等待，请稍候</div>
        <div class="zcdd-info">
          <div class="zcdd-next" :style="{ 'color': stageliveFontColor }">转场：下一位选手</div>
          <div class="zcdd-info-box" id="zcddInfo" v-if="nowProj">
            <span class="city" v-if="stageliveShowContent == 0">{{ nowProj.sortnum<10?"0"+nowProj.sortnum:nowProj.sortnum}}</span>
            <span class="city" v-if="stageliveShowContent == 1">{{ nowProj.contprojRegion }}</span>
            <template v-if="nowProj.contprojName">
              {{ nowProj.contprojName }}
            </template>
          </div>
        </div>
      </div>
      <div class="content lyjd" v-if="partNumber == 1 && voiceTest == 0">
        <div class="lyjd-title" :style="{ 'color': stageliveFontColor }">主题陈述环节倒计时</div>
        <div class="lyjd-countdown" id="luyanTime" :class="{ colorRed: colorRed }"
          :style="{ 'color': stageliveFontColor }">
          {{ TimeCountDown }}</div>
      </div>
      <div class="content dbjd" v-if="partNumber == 2 && voiceTest == 0">
        <div class="dbjd-title" :style="{ 'color': stageliveFontColor }">答辩环节倒计时</div>
        <div class="dbjd-countdown" id="dabianTime" :class="{ colorRed: colorRed }"
          :style="{ 'color': stageliveFontColor }">
          {{ TimeCountDown }}</div>
      </div>
      <div class="content" v-if="partNumber == 3 && voiceTest == 0">
        <div class="psjd">
          <div class="countdown" id="pingshenTime" :class="{ colorRed: colorRed }">{{ TimeCountDown }}</div>
          <div class="right">
            <div class="pingwei">
              <!-- <div class="swiper-container" id="swiper">
                            <div class="swiper-wrapper">
                                
                            </div>
                            <div class="swiper-pagination"></div>
                        </div> -->
              <el-carousel :interval="5000" arrow="never">
                <el-carousel-item v-for="(item, index) in pwList" :key="index">
                  <div class="pw-item" v-for="(slide, idx) in item" :key="idx">
                    <div class="pw-img">
                      <img v-if="slide.judgeStatus != 1" src="../assets/touping-people.png" />
                      <!--<div class="gou" v-if="slide.judgeStatus==1"></div>-->
                      <img v-if="slide.judgeStatus == 1" src="../assets/touping-gou.png" />
                    </div>
                    <div class="pw-name">{{ slide.judgerName }}</div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="progress-box">
              <div class="progress">
                <div class="progress-bar" id="progressBar" :style="{ 'width': progressBarWidth }">
                  <div class="ywc" id="ywc">{{ progressBarWidth }}</div>
                </div>
                <!-- <div class="wwc" id="wwc">40%</div> -->
              </div>
              <div class="wcl" id="wcl">{{ wclHTML }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content gsjd" v-if="partNumber == 4 && voiceTest == 0">
        <div class="gsjd-title" :style="{ 'color': stageliveFontColor }">最终得分</div>
        <div class="gsjd-score" id="gongshiScore" :style="{ 'color': stageliveFontColor }">{{ finalScore }}</div>
      </div>
      <div class="content lyjd" v-if="partNumber == 6 && voiceTest == 0">
        <div class="lyjd-title" :style="{ 'color': stageliveFontColor }">15秒倒计时</div>
        <div class="lyjd-countdown" id="luyanTime" :class="{ colorRed: colorRed }"
          :style="{ 'color': stageliveFontColor }">
          {{ TimeCountDown }}</div>
      </div>
      <div class="content lyjd" v-if="partNumber == 7 && voiceTest == 0">
        <div class="lyjd-title" :style="{ 'color': stageliveFontColor }">点评环节倒计时</div>
        <div class="lyjd-countdown" id="luyanTime" :class="{ colorRed: colorRed }"
          :style="{ 'color': stageliveFontColor }">
          {{ TimeCountDown }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import countdownAudioOgg from '../audio/countdown.ogg'
import countdownAudioWav from '../audio/countdown.wav'
import countdownAudioMp3 from '../audio/countdown.mp3'
import countdownAudioFlac from '../audio/countdown.flac'
import { Howl, Howler } from 'howler';

var timer;
var isPlay = false;

export default {
  data() {
    return {
      stageliveId: null,//现场id
      projData: [],
      cityHtml: null,
      contprojName: null,
      partNumber: null,
      nowProj: [],
      TimeCountDown: null,
      colorRed: false,
      pwList: [],
      progressBarWidth: 0,
      wclHTML: '',
      finalScore: null,
      stageliveBgUrl: null,
      djSec: 0,
      xinSec: 0,
      djTime: null,
      audio: null,
      audio6: null,
      audio7: null,
      stageliveShowContent: 0,
      voiceTest: 0,
      stageliveFontColor: '#ffffff',
      countdownSeconds6: 15,// 新增属性，用于 15 秒倒计时,
      countdownSeconds7: 1080,// 新增属性，用于 1080 秒倒计时,
      // 新增标志位，用于标记是否已经完成过一次倒计时
      hasCountdownFinished6: false,
      hasCountdownFinished7: false,
      djTime6: null,
      djTime7: null,
    }
  },
  methods: {
    //获取指定大赛现场信息
    getStageLive() {
      var that = this;
      this.$post('/api/stagelive/get', {
        stageliveId: this.stageliveId,
      }).then((res) => {
        // console.log(res);
        if (res.result) {
          let data = res.resultData,
            projectList = res.resultData.contprojList;

          this.projData = res.resultData;
          if (data.stageliveBgUrl) {
            this.stageliveBgUrl = this.$store.state.baseHost + "/" + data.stageliveBgUrl;
          }
          if (data.stageliveShowContent) {
            this.stageliveShowContent = data.stageliveShowContent;
          }

          if (data.contprojList.length) {
            if (data.contprojList[0].contprojRegion != null && data.contprojList[0].contprojRegion != '') {
              this.cityHtml = data.contprojList[0].contprojRegion;
            }
            this.contprojName = data.contprojList[0].contprojName;
          }
          if (data.stageliveFontColor) {
            this.stageliveFontColor = data.stageliveFontColor;
          }
          if (!this.$route.query.stop) {
            clearInterval(timer);
            timer = setInterval(function () {
              that.getCurProjPart(data, projectList)
            }, 1000)
          } else {
            clearInterval(timer);
            timer = setTimeout(function () {
              that.getCurProjPart(data, projectList)
            }, 1000)
          }
          if (!this.$route.query.player) {
            isPlay = true;
          }

        } else {
          this.$message.error(res.resultMsg);
        }
      }).catch((res) => {
        console.log(res);
      })
    },
    //获取当前阶段
    getCurProjPart(stageliveData, projectList) {
      this.$post('/api/stagelive/getCurProjPart', {
        stageliveId: this.stageliveId
      }).then((res) => {
        // console.log(res);
        if (res.result) {
          let data = res.resultData, nowProj;
          for (let i = 0; i < projectList.length; i++) {
            if (projectList[i].contprojId == data.contprojId) {
              nowProj = projectList[i];
              // console.log(nowProj)
              break;
            }
          }
          this.nowProj = nowProj;
          this.partNumber = data.partNumber;
          var that = this;
          if (nowProj.contprojRegion != null && nowProj.contprojRegion != '') {
            this.cityHtml = nowProj.contprojRegion;
            this.contprojName = nowProj.contprojName;
          }

          if (data.partNumber == 6 && this.djTime6 === null) {
            this.audio7.pause();
            clearInterval(this.djTime7);
            clearInterval(this.djTime);
            this.djTime7 = null;
            this.djTime = null;
            this.hasCountdownFinished7 = false;
            if (!this.hasCountdownFinished6) {
              this.TimeCountDown = this.timestampToDate(15);
            }
            if (!this.djTime6 && !this.hasCountdownFinished6) {
              this.countdownSeconds6 = 15;
              this.djTime6 = setInterval(() => {
                this.countdownSeconds6--;
                this.TimeCountDown = this.timestampToDate(this.countdownSeconds6);
                if (this.countdownSeconds6 <= 0) {
                  clearInterval(this.djTime6);
                  this.djTime6 = null; // 重置定时器变量
                  this.hasCountdownFinished6 = true;
                }
                if (this.countdownSeconds6 === 10) {
                  this.colorRed = true;
                  this.audio6.seek(0);
                  this.audio6.play();// 音频播放
                } else if (this.countdownSeconds6 === 0) {
                  this.audio6.pause(); // 音频暂停
                }
                if (this.countdownSeconds6 <= 10) {
                  this.colorRed = true;
                }
                else {
                  this.colorRed = false;
                }
              }, 1000);
            }
          }

          if (data.partNumber == 7 && this.djTime7 === null) {
            this.audio6.pause();
            clearInterval(this.djTime6);
            clearInterval(this.djTime);
            this.djTime = null;
            this.djTime6 = null;
            this.hasCountdownFinished6 = false;
            if (!this.hasCountdownFinished7) {
              this.TimeCountDown = this.timestampToDate(1080);
            }
            if (!this.djTime7 && !this.hasCountdownFinished7) {
              this.countdownSeconds7 = 1080;
              this.djTime7 = setInterval(() => {
                this.countdownSeconds7--;
                this.TimeCountDown = this.timestampToDate(this.countdownSeconds7);
                if (this.countdownSeconds7 <= 0) {
                  clearInterval(this.djTime7);
                  this.djTime7 = null; // 重置定时器变量
                  this.hasCountdownFinished7 = true;
                }
                if (this.countdownSeconds7 === 10) {
                  this.audio7.seek(0);
                  this.audio7.play();// 音频播放
                } else if (this.countdownSeconds7 === 0) {
                  this.audio7.pause(); // 音频暂停
                }
                if (this.countdownSeconds7 <= 10) {
                  this.colorRed = true;
                }
                else {
                  this.colorRed = false;
                }
              }, 1000);
            }
          }

          if (data.partNumber == 6 || data.partNumber == 7) {
            // if (!this.djTime) {
            //   this.djSec--;
            //   this.countdown();
            //   this.djTime = setInterval(function () {
            //     that.djSec--;
            //     that.countdown();
            //   }, 1000);
            // }
          } else {
            this.hasCountdownFinished6 = false;
            this.hasCountdownFinished7 = false;
            this.audio7.pause();
            this.audio6.pause();
            clearInterval(this.djTime7);
            clearInterval(this.djTime6);
            this.djTime7 = null;
            this.djTime6 = null;
            //需要倒计时的阶段
            if (data.partNumber == 1 || data.partNumber == 2 || data.partNumber == 3) {
              if (data.partInfo.status == 0 && data.partInfo.startTime == "") {//时间复位
                this.djSec = data.totalSec;
                this.countdown();
                if (this.djTime) { //清除倒计时事件
                  clearInterval(this.djTime);
                  this.djTime = null;
                }
                this.audio.pause(); //音频播放暂停
              } else if (data.partInfo.status == 1 && this.djSec > 0) { //时间开始
                if (!this.djTime) {
                  this.djSec--;
                  this.countdown();
                  this.djTime = setInterval(function () {
                    that.djSec--;
                    that.countdown();
                  }, 1000);
                }
              } else { //时间暂停
                this.djSec = data.partInfo.leftSec;
                this.countdown();
                if (this.djTime) { //清除倒计时事件
                  clearInterval(this.djTime);
                  this.djTime = null;
                }
                if (data.partInfo.leftSec == 0) {
                  setTimeout(() => {
                    this.audio.pause(); //音频播放暂停
                  }, 800);
                } else {
                  this.audio.pause(); //音频播放暂停
                }
              }
            } else {
              if (this.djTime) { //清除倒计时事件
                clearInterval(this.djTime);
                this.djTime = null;
              }
              this.audio.pause(); //音频播放暂停
            }
          }

          if (data.partNumber == 3) {//评审阶段
            //this.countdown(data.partNumber,stageliveData,data);

            // 评委Begin
            let list = [],//用于渲染
              size = 15;//一页10条数据
            const pwdata = res.resultData.judgerList;
            const page = Math.ceil(pwdata.length / size);
            for (let i = 0; i < page; i++) {
              const start = i * size;
              const end = i * size + size;
              const items = pwdata.slice(start, end);
              list.push(items);
            }
            console.log(list)
            this.pwList = list;

            let ywcLengt = 0;
            pwdata.forEach((item, i) => {
              if (item.judgeStatus == 1) {//已评
                ywcLengt++;
              }
            })
            this.progressBarWidth = this.toPercent(ywcLengt, pwdata.length);
            this.wclHTML = ywcLengt + '/' + pwdata.length;

          } else if (data.partNumber == 4) {//公示阶段
            this.finalScore = data.finalScore;
          }


        } else {
          this.$message.error(res.resultMsg);
        }
      }).catch((res) => {
        console.log(res);
      })
    },
    // 倒计时
    countdown() {
      if (this.djSec < 0) { //倒计时不能小于零
        this.djSec = 0;
      }
      this.TimeCountDown = this.timestampToDate(this.djSec);
      if (this.djSec <= 10) {
        this.colorRed = true; //讲倒计时字体设置为红色
        if (this.djSec > 0 && this.djSec <= 10 && !this.audio.playing()) { //如果倒计时小于10秒并且倒计时音频未播放，则开启倒计时声音
          // 播放音频
          this.audio.seek(10 - this.djSec);
          this.audio.play();
        }
      } else {
        this.colorRed = false;
      }
    },
    // 百分比
    toPercent(num, total) {
      return (Math.round(num / total * 100) + "%");// 小数点后两位百分比
    },
    //秒数换算时间
    timestampToDate(ctime) {
      let returnStr = '';

      // 天数位
      // let day = Math.floor(ctime / 3600 / 24);
      // let dayStr = day.toString();
      // if (dayStr.length == 1) dayStr = '0' + dayStr;

      // // 小时位
      // let hr = Math.floor(ctime / 3600);
      // let hrStr = hr.toString();
      // if (hrStr.length == 1) hrStr = '0' + hrStr;
      // returnStr = hrStr;

      // 分钟位
      let min = Math.floor(ctime / 60 % 60);
      let minStr = min.toString();
      if (minStr.length == 1) minStr = '0' + minStr;
      returnStr += minStr;

      // 秒位
      let sec = Math.floor(ctime % 60);
      let secStr = sec.toString();
      if (secStr.length == 1) secStr = '0' + secStr;
      returnStr += ':' + secStr;

      return returnStr;
    }
  },
  mounted() {
    if (this.$route.query.stageliveId) {
      this.stageliveId = this.$route.query.stageliveId;
      this.getStageLive();
    } else {
      this.$message.error('缺少现场id');
    }

    var audios = document.createElement('audio');
    var howlMap = {
      volume: 1,
      loop: false,
      autoplay: false
    };
    //当前浏览器支持哪些格式的音频
    if (audios.canPlayType('audio/mp3') == 'probably') {
      howlMap['src'] = [countdownAudioMp3];
    } else if (audios.canPlayType('audio/ogg') == 'probably') {
      howlMap['src'] = [countdownAudioOgg];
    } else if (audios.canPlayType('audio/wav') == 'probably') {
      howlMap['src'] = [countdownAudioWav];
    } else if (audios.canPlayType('audio/flac') == 'probably') {
      howlMap['src'] = [countdownAudioFlac];
    }
    this.audio = new Howl(howlMap);
    this.audio6 = new Howl(howlMap);
    this.audio7 = new Howl(howlMap);
  },
  beforeDestroy() {
    clearInterval(this.djTime);
    clearInterval(timer);
  }
}
</script>

<style scoped>
.main-body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: url('../assets/touping-bg.png') no-repeat center;
  background-size: cover;
}

.main {
  max-width: 1340px;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

/* .main .title-box{display: none;} */
.main .title {
  font-size: 69px;
  color: #fff;
  font-weight: bold;
  font-family: KaiTi
}

.main .huanjie {
  font-size: 46px;
  color: #fae111;
  font-weight: bold;
}

.main .project-name {
  background: #3f91c9;
  margin-top: 38px;
  border: 1px solid #fff;
  border-radius: 4px;
  overflow: hidden;
  padding: 15px;
  color: #fff;
  font-weight: bold;
  font-size: 36px;
  display: inline-block;
}

.main .project-name .city {
  background: #faab11;
  border-radius: 15px;
  display: inline-block;
  padding: 5px 20px;
  margin-right: 30px;
}

.main .content {
  width: 100%;
}

/* 转场等待 */
.main .content.zcdd .zcdd-title {
  color: #0BC0FB;
  font-size: 150px;
  margin-top: 10%;
}

.main .content.zcdd .zcdd-info {
  font-size: 60px;
  color: #fff;
  margin-top: 10%;
}

.main .content.zcdd .zcdd-info .zcdd-next {
  margin-bottom: 8px;
  font-size: 60px;
}

.main .content.zcdd .zcdd-info-box {
  font-size: 50px;
  color: yellow;
}

.main .content.zcdd .zcdd-info-box .city {
  font-size: 50px;
  color: #fff;
  background: #faab11; /*#0A4477 注释为之前颜色*/
  border: 1px solid #0BADE7;
  border-radius: 8px;
  display: inline-block;
  padding: 10px;
}

/* 路演阶段、答辩阶段、公示阶段 */
.main .content.lyjd .lyjd-title,
.main .content.dbjd .dbjd-title,
.main .content.gsjd .gsjd-title {
  font-size: 46px;
  color: #fff;
  margin: 50px 0;
}

.main .content.lyjd .lyjd-countdown,
.main .content.dbjd .dbjd-countdown,
.main .content.gsjd .gsjd-score {
  font-size: 307px;
  color: #fff;
}

.lyjd-countdown {
  margin-top: -69px;
  margin-bottom: -45px;
}

.dbjd-countdown {
  margin-top: -69px;
  margin-bottom: -45px;
}

.gsjd-score {
  margin-top: -69px;
  margin-bottom: -45px;
}

/* 评审阶段 */
.main .content .psjd {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -28px;
}

.main .content .psjd .countdown {
  padding: 50px;
  background: url('../assets/touping-center.png') repeat-x center;
  position: relative;
  color: #fff;
  font-size: 150px;
  text-align: left;
  height: 380px;
  line-height: 380px;
}

.main .content .psjd .countdown::before,
.main .content .right::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: url('../assets/touping-left.png') no-repeat left;
  height: 100%;
  width: 50px;
  background-size: contain;
}

.main .content .psjd .countdown::after,
.main .content .right::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background: url('../assets/touping-right.png') no-repeat right;
  height: 100%;
  width: 50px;
  background-size: contain;
}

/* .main .content .psjd .countdown span{display: inline-block;width: 66px;height: 66px;line-height:66px;text-align: center;border-radius: 8px;border: 2px solid #fff;background: #f9f9f9;color: #464646;font-size: 50px;position: relative;}
.main .content .psjd .countdown span::before{content:'';width: 100%;height: 1px;background: #a6a9af;position: absolute;left: 0;top: 32px;}
.main .content .psjd .countdown span::after{content:'';width: 100%;height: 1px;background: #fff;position: absolute;left: 0;top: 33px;}
.main .content .psjd .countdown .countdown-box{height:70px;line-height:70px;vertical-align: middle;margin: 40px 0;} */

.main .content .psjd .right {
  height: 380px;
  padding: 50px 0;
  background: url('../assets/touping-center.png') repeat-x center;
  position: relative;
}

.main .content .psjd .right .pingwei {
  width: 720px;
  height: 350px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0 50px;
}

.main .content .psjd .right .pingwei>>>.el-carousel__container {
  width: 720px;
  height: 350px;
}

.main .content .psjd .right .pingwei .pw-item {
  margin: 0 43px 0 22px;
  float: left;
}

.main .content .psjd .right .pingwei .pw-item .pw-img {
  /*width: 100px; 原来一页显示10条
  /*height: 100px;*/
  width: 70px;
  height: 70px;
  border: 1px solid #0dbfd2;
  border-radius: 50%;
  box-sizing: content-box;
  position: relative;
  overflow: hidden;
}

.main .content .psjd .right .pingwei .pw-item .pw-img img {
  width: 100%;
  height: 100%;
  display: block;
}

.main .content .psjd .right .pingwei .pw-item .pw-img .gou {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-size: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7) url('../assets/touping-gou.png') no-repeat center;
  color: #0dbfd2;
  border-radius: 50%;
  background-size: 50%;
}

.main .content .psjd .right .pingwei .pw-item .pw-name {
 /* 原来10个一页样式
  width: 100%;
  text-align: center;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  color: #58dbff;*/
  width: 100%;
  text-align: center;
  height: 38px;
  line-height: 30px;
  font-size: 20px;
  color: #58dbff; 
}

/deep/ .el-carousel__indicator--horizontal {
    display: none;
    padding: 12px 4px;
}

.swiper-pagination-bullet {
  background: #fff !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: #007aff !important;
}

.colorRed {
  color: red !important;
}

/* .main .content .right .pingwei .swiper-slide{
  width: 720px;height: 100%;overflow: hidden;position: relative;color: #fff;font-size: 150px;text-align: left;height: 300px;display: flex;flex-direction: row;align-items: center;float: left;
} */


.progress-box {
  display: flex;
}

.progress-box .wcl {
  font-size: 33px;
  color: #fff;
  height: 48px;
  line-height: 48px;
  margin-left: 20px;
}

.progress {
  background: #007ad0;
  border-radius: 50px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  overflow: hidden;
  border: 2px solid #77cbfa;
  display: flex;
  flex: 1;
}

.progress-bar {
  position: relative;
  height: 48px;
  border-radius: 50px;
  /* -webkit-transition: 0.4s linear;
    -moz-transition: 0.4s linear;
    -o-transition: 0.4s linear;
    transition: 0.4s linear;
    -webkit-transition-property: width, background-color;
    -moz-transition-property: width, background-color;
    -o-transition-property: width, background-color;
    transition-property: width, background-color; */
  width: 75%;
  background-size: 3em 3em;
  background-image: linear-gradient(-45deg, #0097fc 0em, #0097fc 0.8em, #00e4fd 0.9em, #00e4fd 2.1em, #0097fc 2.1em, #0097fc 2.9em, #00e4fd 3.1em);
  -webkit-animation: warning-animation 750ms infinite linear;
  -moz-animation: warning-animation 750ms infinite linear;
  animation: warning-animation 750ms infinite linear;
  overflow: hidden;
}

.progress-bar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.progress-bar::after {
  z-index: 2;
  bottom: 45%;
  border-radius: 50px;
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
}

.progress .ywc {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 40px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  width: 100%;
}

.progress .wwc {
  color: #fff;
  flex: 1;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 40px;
}

/* .progress {
    width: 100%;
}
.progress .warning {
    height: 50px;
}
.progress .warning {
    position: relative;
    background-color: #6DA807;
    border: 1px solid #6DA807;
    border-radius: 10px;
    box-shadow: 1vw 3vh 10vh rgba(109, 168, 7, 0.8);
    
  } */
@-webkit-keyframes warning-animation {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 3em 0;
  }
}

@-moz-keyframes warning-animation {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 3em 0;
  }
}

@keyframes warning-animation {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 3em 0;
  }
}

/* .warning:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    border-radius: 10px;
    background-image: linear-gradient(to bottom, #6DA807, rgba(171, 226, 77, 0.6) 15%, transparent 60%, #6DA807);
  } */
</style>