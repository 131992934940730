var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"hidden-md-and-down"},[_c('nav-bar',{attrs:{"currentId":99}}),_c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":this.$store.state.baseHost + '/fs/download?storeKey=' + _vm.orgCover,"onerror":_vm.defaultBannerImg}})]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"ps-title"},[_vm._v("赛事评审")]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"mainBox"},[_c('div',{staticClass:"p-navbar"},[_c('span',[_vm._v("赛事状态")]),_c('div',{staticClass:"p-bar",attrs:{"id":"selectType"}},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,staticClass:"p-bar-item",class:{ active: _vm.current == item.id },on:{"click":function($event){return _vm.activeFn(item.id, item.param)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('input',{attrs:{"type":"text","id":"saishiHtml","hidden":""}})]),_c('div',{staticClass:"pd-list"},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,staticClass:"pd-item"},[_c('div',{staticClass:"pd-img"},[_c('img',{attrs:{"src":item.contestCoverUrl
                        ? _vm.$store.state.baseHost +
                          '/fs/download?storeKey=' +
                          item.contestCoverUrl
                        : require('../assets/ps-img.png'),"onerror":_vm.defaultImg}}),(item.stageStatus == 1)?_c('div',{staticClass:"type"},[_vm._v("评审中")]):_vm._e(),(item.stageStatus == -1)?_c('div',{staticClass:"type yjs"},[_vm._v(" 已结束 ")]):_vm._e(),(item.stageStatus == 0)?_c('div',{staticClass:"type wks"},[_vm._v(" 未开始 ")]):_vm._e()]),_c('div',{staticClass:"pd-info"},[_c('div',{staticClass:"pd-info-title"},[_vm._v(_vm._s(item.contestName))]),_c('div',{staticClass:"pd-info-stage-title"},[_vm._v("评审环节名："),_c('span',{staticClass:"pd-stage-name"},[_vm._v(_vm._s(item.stageName))])]),_c('div',{staticClass:"pd-info-time"},[_vm._v(" 评审开始时间："+_vm._s(item.stageStarttime==null||item.stageStarttime=='' ? "暂无":item.stageStarttime.split(" ")[0]+" "+item.stageStarttime.split(" ")[1])+" ")]),_c('div',{staticClass:"pd-info-time"},[_vm._v(" 评审截止时间："+_vm._s(item.stageEndtime==null||item.stageStarttime=='' ? "暂无":item.stageEndtime.split(" ")[0]+" "+item.stageEndtime.split(" ")[1])+" ")]),_c('div',{staticClass:"pd-info-proccess"},[_c('div',{staticClass:"pd-info-proccess-yp"},[_vm._v(" 已评项目："+_vm._s(item.contprojOkCnt)+" ")]),_c('div',{staticClass:"pd-info-proccess-wp"},[_vm._v(" 未评项目："+_vm._s(item.contprojTotalCnt - item.contprojOkCnt)+" ")]),_c('div',{staticClass:"pd-info-proccess-wcl"},[_vm._v(" 评审完成率："+_vm._s(_vm.getnum(item.contprojOkCnt, item.contprojTotalCnt))+" ")])])]),_c('div',{staticClass:"pd-btn"},[(item.stageStatus == 1)?_c('router-link',{attrs:{"to":{
                      name: 'pingshen_list',
                      query: {
                        stageId: item.stageId,
                        contestId: item.contestId,
                      },
                    },"href":"#"}},[_vm._v("开始评审")]):_vm._e()],1)])}),0),_c('div',{staticClass:"all-data"},[_vm._v("已显示所有赛事")])])])]),_c('bottom-bar')],1),_c('div',{staticClass:"hidden-lg-and-up phone_padding"},[_c('phone-header',{attrs:{"phoneCurrentId":99}}),_c('div',{staticClass:"xs_main"},[_c('div',{staticClass:"xs_ps_wrap"},[_c('div',{staticClass:"xs_ps_title"},[_vm._v("赛事评审")]),_c('div',{staticClass:"xs_ps_main"},[_c('div',{staticClass:"xs_tab_category"},[_c('van-tabs',{attrs:{"animated":""},on:{"click":function($event){return _vm.handleChangeVanTab($event)}}},_vm._l((_vm.tabList),function(item,index){return _c('van-tab',{key:index,attrs:{"title":item.name}},_vm._l((_vm.arrList),function(item){return _c('div',{key:item.stageId,staticClass:"activity_item_wrapper"},[_c('div',{staticClass:"activity_item_wrap"},[_c('div',{staticClass:"activity_item_left"},[_c('div',{staticClass:"activeity_item_img_wrap"},[_c('img',{attrs:{"src":item.contestCoverUrl
                        ? _vm.$store.state.baseHost +
                          '/fs/download?storeKey=' +
                          item.contestCoverUrl
                        : require('../assets/ps-img.png'),"onerror":_vm.defaultImg,"alt":""}}),(item.stageStatus == 1)?_c('div',{staticClass:"xs_type xs_doing"},[_vm._v("评审中")]):_vm._e(),(item.stageStatus == -1)?_c('div',{staticClass:"xs_type xs_yjs"},[_vm._v("已结束")]):_vm._e(),(item.stageStatus == 0)?_c('div',{staticClass:"xs_type xs_wks"},[_vm._v("未开始")]):_vm._e()])]),_c('div',{staticClass:"activity_item_center"},[_c('div',{staticClass:"item_title van-multi-ellipsis--l2"},[_vm._v(" "+_vm._s(item.contestName))])]),_c('div',{staticClass:"activity_item_right"},[(item.stageStatus == 1)?_c('van-button',{staticStyle:{"width":"100%"},attrs:{"type":"info","size":"small","to":{
                              name: 'pingshen_list',
                              query: {
                                stageId: item.stageId,
                                contestId: item.contestId,
                              },
                            }}},[_vm._v("开始评审")]):_vm._e()],1)]),_c('div',{staticClass:"item_info"},[_c('p',{staticClass:"van-ellipsis"},[_vm._v("评审起止时间： "),(item.stageStarttime==null && item.stageEndtime==null)?[_c('span',[_vm._v("暂无")])]:_vm._e(),(item.stageStarttime!=null && item.stageEndtime!=null)?[_c('span',[_vm._v(_vm._s(item.stageStarttime==null||item.stageStarttime=='' ? '暂无' : item.stageStarttime.split(" ")[0]+" "+item.stageStarttime.split(" ")[1]))]),_vm._v(" ~ "),_c('span',[_vm._v(_vm._s(item.stageEndtime==null||item.stageEndtime=='' ? '暂无' : item.stageEndtime.split(" ")[0]+" "+item.stageEndtime.split(" ")[1]))])]:_vm._e()],2),_c('div',{staticClass:"item_proccess"},[_c('span',[_vm._v("已评项目："+_vm._s(item.contprojOkCnt)+" ")]),_c('span',[_vm._v("未评项目："+_vm._s(item.contprojTotalCnt - item.contprojOkCnt)+" ")]),_c('span',[_vm._v("评审完成率："+_vm._s(_vm.getnum(item.contprojOkCnt, item.contprojTotalCnt)))])])])])}),0)}),1),_c('van-empty',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPhoneEmpty),expression:"showPhoneEmpty"}],staticClass:"custom-image",attrs:{"image":"https://img01.yzcdn.cn/vant/custom-empty-image.png","description":"暂无内容"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.controlShowAllDataText),expression:"controlShowAllDataText"}],staticClass:"all-data",staticStyle:{"font-size":".3rem","margin":".8rem 0"}},[_vm._v("已显示所有赛事")])],1)])])]),_c('phone-footer')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }