<template>
  <header>
      <div class="content">
        <router-link class="logo"  :to="'/zgpingshen_index'"><img src="../assets/stucplan/logo.png" ></router-link>
            <ul class="tabs">
                <!-- <li class="active"><a href="/">首页</a></li>
                <li><a href="#">大赛介绍</a></li>
                <li><a href="#">在线报名</a></li>
                <li><a href="#">大赛公告</a></li>
                <li><a href="#">资料下载</a></li> -->
                <template v-for="(item,index) in navbarData">
                    <li v-bind:key="index" v-bind:class="currentId==item.id?'active':''" v-if="item.show"><router-link :to="item.link">{{item.name}}</router-link></li>
                </template>
            </ul>
        <div class="btns navbar-right" v-if="!this.$store.state.userInfo">
            <span><router-link :to="'/stucpan/zglogin/'+this.$store.state.oid" class="logbtn">评委登录</router-link></span>
            <!-- <a :href="this.$store.state.baseHost + '/console'" target="_blank" class="logbtn">管理登录</a>-->
            <!-- <router-link to="/register" class="logbtn">注册</router-link> -->
        </div>
        <div class="navbar-right" v-else>
            <li class="dropdown">
            <div class="adm-avatar"><a href="#"><img id="loginPhoto" :src="this.$store.state.userInfo.memberPhotourl?this.$store.state.baseHost+'/fs/download?storeKey='+this.$store.state.userInfo.memberPhotourl:require('../assets/photo0.png')"></a></div>
            <div class="adm-link"><a href="javascript:void(0)">{{this.$store.state.userInfo.memberName}}</a></div>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                <!-- <li id="updatePwdBtn"><a href="javascript:void(0)" @click="pwdVisible = true"><img src="../assets/lock.png" ><span>修改密码</span></a></li>-->
                <li id="uc"><a href="javascript:void(0)" @click="goCenter"><img src="../assets/ucicon.png" ><span>个人中心</span></a></li>
                <li id="lo"><a href="javascript:void(0)" @click="logout()"><img src="../assets/out.png" ><span>退出登录</span></a></li>
                </ul>
            </li>
        </div>
    </div>
     <el-dialog title="修改密码" width="35%" :visible.sync="pwdVisible" :close-on-click-modal="false" :modal-append-to-body='false' center>
        <el-form :model="form.formData" :rules="form.formRules" ref="form"  label-width="37%">
            <div class="col-lg-offset-2">
                <div class="profile-row">
                    <el-form-item label="当前密码：" prop="oldPassword">
                        <el-input v-model="form.formData.oldPassword" show-password type="password" style="width:auto" autocomplete="off" placeholder="请输入当前密码" clearable></el-input>
                    </el-form-item>
                </div>
                <div class="profile-row">
                    <el-form-item label="新密码：" prop="newPassword">
                        <el-input v-model="form.formData.newPassword" show-password type="password" style="width:auto" autocomplete="off" placeholder="请输入新密码" clearable></el-input>
                    </el-form-item>
                </div>
                <div class="profile-row">
                    <el-form-item label="确认密码：" prop="newPassword2">
                        <el-input v-model="form.formData.newPassword2" show-password type="password" style="width:auto" autocomplete="off" placeholder="请再次输入新密码" clearable></el-input>
                    </el-form-item>
                </div>
            </div>
        </el-form>
        <div slot="footer" class="dialog-footer" style="margin-top: 5px;">
            <el-button @click="cancelPWD('form')">取消</el-button>
            <el-button type="primary" @click="changePWD()">保存</el-button>
        </div>
    </el-dialog>
  </header>
</template>

<script>
import Vue from 'vue'

export default {
    name: "index",
    props:{
        currentId: {
            default: 0,

        },
    },
    data(){
        return{
            // orgLogo: null,
            pwdVisible: false,
            defaultLogoImg: 'this.src="' + require("../assets/logo.png") + '"',
            form:{
				formData:{
					oldPassword: '',//当前密码
					newPassword: '',//新密码
					newPassword2: '',//确认密码
				},
				formRules:{
					oldPassword: [
						{ required: true, message: '当前密码不能为空', trigger: 'blur' },
                    	{ min: 6, max: 36, message: '长度在 6 到 36 个字符', trigger: 'blur' }
                    ],
                    newPassword: [
						{ required: true, message: '新密码不能为空', trigger: 'blur' },
                    	{ min: 6, max: 36, message: '长度在 6 到 36 个字符', trigger: 'blur' }
                    ],
                    newPassword2: [
                        { required: true, message: '确认密码不能为空', trigger: 'blur' },
                    	{ min: 6, max: 36, message: '长度在 6 到 36 个字符', trigger: 'blur' }
                    ],
				}
			},
            navbarData:[
                {
                    name: '首页',
                    link: '/home/'+this.$store.state.oid,
                    id: 0,
                    show: true,
                },
                {
                    name: '课程广场',
                    link: '/kechengguangchang',
                    id: 5,
                    show: true,
                },
                {
                    name: '大赛介绍',
                    link: '/dasaijieshao',
                    id: 1,
                    show: true,
                },
                {
                    name: '在线报名',
                    link: '/zaixianbaoming',
                    id: 2,
                    show: true,
                },
                {
                    name: '往期赛事',
                    link: '/wangqisaishi_list',
                    id: 3,
                    show: true,
                },
                // {
                //     name: '大赛公告',
                //     link: '/dasaigonggao',
                //     id: 3
                // },
                // {
                //     name: '资料下载',
                //     link: '/ziliaoxiazai',
                //     id: 4
                // },
            ]
        };
    },
    methods: {
        logout(){
            console.log('进来了');
			this.$confirm('是否退出登录?', '提示', {
                confirmButtonText: '是',
                cancelButtonText: '否',
                type: 'warning'
            }).then(() => {
            //点击确定的操作(调用接口)
            this.$post("/api/appuser/logout").then((res) => {
					//console.log(res);
					if (res.result) {
						this.$store.commit("LOG_OUT");
                        sessionStorage.clear();
                        this.$router.push("/stucpan/zglogin/"+this.$store.state.oid);
                       
					}
				});
            }).catch(() => {
            //点取消的提示
                return;
            });
        },
        changePWD(){
			if(this.form.formData.newPassword!=this.form.formData.newPassword2){
				this.$message.error('两次输入的新密码不一致，请检查');
				return
			}
			this.$post('/api/appuser/updatePassword',
			{
				oldPassword:this.form.formData.oldPassword,
				newPassword:this.form.formData.newPassword
			}).then((res)=>{
				console.log(res);
				if(res.result){
					this.$message.success(res.resultMsg);
					//这里执行登出
					this.$post("/api/appuser/logout").then((res) => {
						console.log(res);
						if (res.result) {
							this.$store.commit("LOG_OUT");
                            this.$router.push("/stucpan/zglogin/"+this.$store.state.oid);
                            
						}
					});
				}else{
					this.$message.error(res.resultMsg);
				}
			}).catch((res)=>{
				console.log(res);
			})
        },
        cancelPWD(formName){
            this.$refs[formName].resetFields();
            this.pwdVisible = false;
        },
        goCenter(){
            if(this.$store.state.userInfo.student){
                this.$router.push({name: 'student_profile'});
            }else{
                this.$router.push({name: 'zgpingshen_index'});
                
            }
        },
    },
    created () {
        // console.log(this.$store.state.userInfo)
        // if(this.$store.state.orgData){
        //     this.orgLogo = this.$store.state.orgData.detail.orgLogo;
        // }
        
        
        
        Vue.set(this.navbarData[0], `show`, false);
        Vue.set(this.navbarData[1], `show`, false);
        Vue.set(this.navbarData[2], `show`, false);
        Vue.set(this.navbarData[3], `show`, false);
        Vue.set(this.navbarData[4], `show`, false);
 


    }
}
</script>

<style scoped>
/* 头部样式 */
header{width: 100%;min-width: 1200px;height: 59px;background: #fff;box-shadow: 0px 3px 7px 0px rgba( 0, 0, 0 ,0.1);padding: 15px 0;}
header .content{width: 1200px;height: 59px;margin: 0 auto;display: flex;justify-content: space-between;}
header .content .logo{padding:5px;max-width: 532px;overflow: hidden;box-sizing: content-box;}
header .content .logo img{height: 100%;}
header .content .tabs{width:auto;height: 30px;display: flex;margin-top: 15px;flex:1;justify-content: flex-end;margin-left: 30px;}
header .content .tabs li{float:left;text-align:center;width: auto;cursor: pointer;margin-right: 40px;color:#222222;font-family: 'Antic', sans-serif;font-size:18px;}
header .content .tabs li a{color: #222;display: block;height: 100%;}
header .content .tabs li.active{border-bottom:2px solid #f79238;color: #f79238;}
header .content .tabs li:hover{border-bottom:2px solid #f79238;color: #f79238;}
header .content .tabs li:hover a{color: #f79238;}
header .content .tabs li.active a{color: #f79238;}
header .content .btns a.logbtn{width: auto;height: 30px;line-height:30px;border:1px solid #5786c7;border-radius: 4px;margin-left: 15px;margin-top: 14px;color: #5786c7;cursor: pointer;display: inline-block;text-align:center;padding: 0 10px;}

.dropdown{
    height: 58px;
    display: flex;
    align-items: center;
    position: relative;
}
.adm-avatar{
	width: 40px;
	/* float: left; */
}
.adm-avatar img{
	width: 40px;
	height: 40px;
	border-radius: 40px;
    display: block;
}
.adm-link{
	color: #038eeb;
	text-align: center;
	line-height: 1;
	margin-left: 10px;
}
.adm-link a{
	color: #038eeb;
}
.admin-nav{
	padding: 5px 0;
}
.admin-nav>li{
	padding: 15px 0;
}
.navbar-right .dropdown-menu {
    top: 98%;
    right: -15px;
    width: 130px;
    position: absolute;
    z-index: 1000;
    float: left;
    min-width: 100px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    display: none;
}
.dropdown-menu li a {
    padding: 10px 0;
    text-align: center;
    line-height: 1;
}

.dropdown-menu>li>a {
    display: block;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}
.dropdown-menu li a img {
    margin-right: 5px;
}
.dropdown-menu li a * {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
}
.dropdown:hover .dropdown-menu{
    display: block;
}
</style>