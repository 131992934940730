<template>
	<div>
		<div class="hidden-md-and-down">
			<nav-bar :currentId="99"></nav-bar>
			<div class="banner">
				<img :src="this.$store.state.baseHost+'/fs/download?storeKey='+orgCover" :onerror="defaultBannerImg" />
			</div>
			<div class="main">
				<div class="ps-title">
					<router-link to="/pingshen_index">赛事评审</router-link>/参赛项目列表
				</div>
				<div class="container">
					<div class="mainBox">
						<div class="saishi-box">
							<div class="saishi-pic">
								<img :src="stageInfo.contestCoverUrl?$store.state.baseHost+'/fs/download?storeKey='+stageInfo.contestCoverUrl:require('../assets/ps-img.png')"
									:onerror="defaultImg" />
							</div>
							<div class="saishi-info">
								<div class="saishi-title">
									<span class="t1">{{stageInfo.contestName}}</span>
									<el-button @click="goCenter">返回个人中心</el-button>
								</div>
								<div class="saishi-type">
									<div class="saishi-type-blue">{{stageInfo.categoryName}}</div>
									<div class="saishi-type-orange" v-if="stageInfo.stageStatus==1">评审中</div>
									<div class="saishi-type-orange" v-if="stageInfo.stageStatus==-1">已结束</div>
									<div class="saishi-type-orange" v-if="stageInfo.stageStatus==0">未开始</div>
								</div>
								<div class="saishi-time" id="saishiTime">
									评审开始时间：{{stageInfo.stageStarttime==null?'待定':stageInfo.stageStarttime}} 至
									{{stageInfo.stageEndtime==null?'待定':stageInfo.stageEndtime}}
								</div>
								<div class="saishi-proccess">
									<div class="saishi-proccess-yp" id="xiangmuYP">已评项目：{{stageInfo.contprojOkCnt}}</div>
									<div class="saishi-proccess-wp" id="xiangmuWP">
										未评项目：{{stageInfo.contprojTotalCnt - stageInfo.contprojOkCnt}}
									</div>
									<div class="saishi-proccess-wcl" id="xiangmuWCL">
										评审完成率：{{getnum(stageInfo.contprojOkCnt,stageInfo.contprojTotalCnt)}}
									</div>
								</div>
								<div class="saishi-btn-box">
									<div class="saishi-countdown" v-if="saishiCountDownShow">剩余评审时间 &nbsp; 
										<span id="countDown">{{stageInfo.stageEndtime==null ? '待定':this.countDownTime}}</span>
									</div>
									<div class="saishi-countdown" v-else>评审已结束</div>
									<div class="saishi-btn" id="showRules" @click="ShowRulesMask">评审须知</div>
									<div class="saishi-btn" id="showConfirmation" v-if="showConfirmationBtn"
										@click="ShowConfirmationMask">{{ showSeeConfirm?'已签署':'签署确认单' }}</div>
									<div class="saishi-btn" id="confirmList" v-if="showSeeConfirm"
										@click="showConfirmListFN">查看确认单</div>
								</div>
							</div>
						</div>

						<div class="p-navbar-box">
							<div class="p-navbar">
								<span>评审状态</span>
								<div class="p-bar" id="stagejudgeChange">
									<div class="p-bar-item" :class="{'active':currentNav==0}" @click="changePSType(0, 1)" data-value=''>全部</div>
									<div class="p-bar-item" :class="{'active':currentNav==1}" @click="changePSType(1, 1)" data-value='1'>已评审({{stageInfo.contprojOkCnt}})</div>
									<div class="p-bar-item" :class="{'active':currentNav==2}" @click="changePSType(2, 1)" data-value='0'>未评审({{stageInfo.contprojTotalCnt - stageInfo.contprojOkCnt}})</div>
								</div>
								<input type="text" id="stagejudgeStatus" hidden />
							</div>
							<div class="p-navbar">
								<span>项目名称：</span>
								<div class="p-bar">
									<div class="search-content">
										<el-input v-model="searchName" placeholder="请输入项目名称"
											prefix-icon="el-icon-search" clearable></el-input>
										<el-button type="primary" style="margin-left:10px;" @click="search">搜索</el-button>
									</div>
								</div>
								<el-button type="danger" style="margin-left:10px;" @click="reload">刷新</el-button>
							</div>
						</div>
						<div class="p-navbar-box">
							<div class="p-navbar">
								<span>项目类别</span>
								<div class="p-bar" id="stagejudgeChange">
									<template v-for="(item,index) in industryList">
										<div class="p-bar-item" :class="{'active':currentIn==index}" @click="changeIndustry(index, index==0 ? '':item.contprojIndustry)" data-value=''>{{index==0 ? item.contprojIndustry:item.contprojIndustry+'(未评分'+item.noCnt+')'}}</div>
									</template>
								</div>
							</div>
						</div>
						<template v-for="(item,index) in industryList">
							<template v-if="index>0 && zbProjList[item.contprojIndustry]">
								<div style="text-align: center; font-size: 28px; padding:10px 0 10px; background-color: #03a9f4; color:#fff;">{{item.contprojIndustry}}</div>
								<el-table :data="zbProjList[item.contprojIndustry]" border>
									<el-table-column type="index" label="序号" width="70px" align="center"></el-table-column>
									<el-table-column prop="contprojName" label="项目名称" width="300" align="center"></el-table-column>
									<el-table-column prop="contprojGroup" label="项目组别" width="180" align="center"></el-table-column>
									<el-table-column prop="contprojIndustry" label="项目类别" width="180" align="center"></el-table-column>
									<el-table-column prop="stagejudgeScore" label="项目分数" align="center">
										<template slot-scope="{row}">
											{{ row.stagejudgeScore>=0?row.stagejudgeScore:'--' }}
										</template>
									</el-table-column>
									<el-table-column label="操作" align="center">
										<template slot-scope="scope">
											<el-button type="primary" size="medium" @click="goDetail(scope.$index, scope.row, currentPage)"
												v-if="!scope.row.stagejudgeScore && scope.row.stagejudgeScore!=0">评审</el-button>
											<el-button type="success" size="medium" @click="goDetail(scope.$index, scope.row, currentPage)"
												v-else>查看</el-button>
										</template>
									</el-table-column>
								</el-table>
							</template>
						</template>
						<div class="alert" v-if="projList.length!=nowList.length">
							<div v-if="projList.length-nowList.length>0" style="font-size: medium;color: black;font-weight: 500;">未出现项目还有：
								<span style="color: red;">{{projList.length - nowList.length}}</span> 个
							</div>
							<div v-else style="font-size: medium;">所有项目已全部展示								
							</div>  
						</div>
						<el-pagination style="text-align: center;margin-top:30px" @size-change="handleSizeChange"
							@current-change="handleCurrentChange" :current-page="currentPage"
							:page-sizes="[20, 30, 50, 100]" :page-size="pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
							<!--//这是显示总共有多少数据，-->
						</el-pagination>
					</div>
				</div>
			</div>
			<div class="mask" v-if="showMask"></div>
			<div class="rules" id="rules" v-if="showRules">
				<div class="rule-title">评审须知</div>
				<div class="rule-content" v-html="stageInfo.stageRemark"></div>
				<div class="rule-tips" style="color:red">本人已熟知“评委审前须知”并同意“评审承诺”</div>
				<div class="rule-confirm" @click="ShowRulesMask">开始评审</div>
			</div>
			<div class="confirmation" v-if="showConfirmation">
				<div class="close-btn" @click="ShowConfirmationMask"></div>
				<div class="confirmation-title">评审结果确认</div>
				<div class="confirmation-content">
					<div class="box">
						<div class="name">
							<span class="num">1</span>下载评审确认单
						</div>
						<div class="icon1"></div>
						<!-- <a class="download-btn" @click="downloadConfirm">下载确认单</a> -->
						<el-button size="small" class="download-btn" @click="downloadConfirm">下载确认单</el-button>
					</div>
					<div class="hr"></div>
					<div class="box">
						<div class="name">
							<span class="num">2</span>签署确认单
						</div>
						<div class="icon2" id="icon2"></div>
						<router-link :to="{name:'pingshen_queren',query:{stageId:this.stageId,contestId: this.contestId}}"
							class="qianmingBtn" href="#">签署确认单</router-link>
						<div id="show"></div> <!--预留用来显示图片-->
					</div>
				</div>
				<el-button class="confirmation-btn" type="primary" @click="ShowConfirmationMask">关闭</el-button>
			</div>
			<div class="confirmationList" id="confirmationList" v-if="showConfirmList">
				<div class="title">已提交的确认单</div>
				<div class="list-box">
					<img :src="$store.state.baseHost + '/' + item" v-for="(item,index) in confirmList" :key="index" />
				</div>
				<div class="close-small-btn" @click="showConfirmListFN"></div>
				<div class="close-btn" @click="showConfirmListFN">关闭</div>
			</div>

			<bottom-bar></bottom-bar>
		</div>

		<!-- 移动端 -->
		<div class="hidden-lg-and-up phone_padding" style="overflow: inherit;">
			<phone-header :phoneCurrentId="99"/>
			
			<van-image width="100%" height="100%" fit="contain" style="display:block" :src="this.$store.state.baseHost + '/fs/download?storeKey=' + orgCover">
				<template v-slot:error>
					<img :src="defaultBannerImg" alt="" style="width: 100%; height: 100%; object-fit: contain" />
				</template>
			</van-image>

			<div class="xs_main">
				<div class="xs_ps_wrap">
					<div class="xs_ps_title"><span @click="goList()">赛事评审</span>/参赛项目列表</div>
					<div class="xs_ps_main">
						<div style="display: flex;align-items: center;justify-content: space-between;padding: .2rem .4rem;">
							<div class="">
								<van-button style="width:100%" type="default" size="small" @click="goCenter()">返回</van-button>
							</div>
							<!-- 剩余评审时间 {{this.countDownTime}} -->
							<van-button plain type="warning" size="small" v-if="saishiCountDownShow" style="font-weight:700">{{stageInfo.stageEndtime==null ? '剩余评审时间 待定':'剩余评审时间 ' + this.countDownTime}} </van-button>
							<van-button plain type="warning" size="small" v-else style="font-weight:700">评审已结束</van-button>
						</div>

						<div class="activity_item_wrapper">
							<div class="activity_item_wrap">
								<div class="activity_item_left">
									<div class="activeity_item_img_wrap">
										<img :src="stageInfo.contestCoverUrl ? $store.state.baseHost + '/fs/download?storeKey=' +
										      stageInfo.contestCoverUrl : require('../assets/ps-img.png')" :onerror="defaultImg" alt="">
									</div>
								</div>
								<div class="activity_item_center">
									<div class="item_title van-multi-ellipsis--l2">
										{{stageInfo.contestName}}
									</div>
									<div class="tag_wrap">
										<van-tag type="primary" size="medium">{{stageInfo.categoryName}}</van-tag>
										<div class="phone-saishi-type" v-if="stageInfo.stageStatus==1">评审中</div>
										<div class="phone-saishi-type phone-saishi-type-yjs" v-if="stageInfo.stageStatus==-1">已结束</div>
										<div class="phone-saishi-type phone-saishi-type-wks" v-if="stageInfo.stageStatus==0">未开始</div>
									</div>
								</div>
							</div>

							<div class="item_info">
								<p class="van-ellipsis">评审开始时间：
									<span>{{stageInfo.stageStarttime==null?'待定':stageInfo.stageStarttime}}</span> ~
									<span>{{stageInfo.stageEndtime==null?'待定':stageInfo.stageEndtime}}</span>
								</p>
								<div class="item_proccess">
									<span>已评项目：{{stageInfo.contprojOkCnt}} </span>
									<span>未评项目：{{stageInfo.contprojTotalCnt - stageInfo.contprojOkCnt}} </span>
									<span>评审完成率：{{getnum(stageInfo.contprojOkCnt,stageInfo.contprojTotalCnt)}}</span>
								</div>
							</div>

							<div class="btn_group">
								<van-button plain type="info" size="mini" @click="showPhoneRulesMask()">评审须知</van-button>
								<van-button plain type="info" size="mini" @click="phoneShowConfirmAction" v-if="showConfirmationBtn">{{ showSeeConfirm?'已签署':'签署确认单' }}</van-button>
								<van-button plain type="info" size="mini" @click="phoneCheckConfirmMenuList = true" v-if="showSeeConfirm">查看确认单</van-button>
							</div>
						</div>

						<van-divider style="margin: .1rem 0;"/>
						<van-search
								show-action
								placeholder="请输入项目名称"
								@search="search"
								v-model="searchName">
								<template #action>
									<van-button type="info" size="small" @click="search">搜索</van-button>
								</template>
						</van-search>
						<div class="xs_tab_category" id="xs_list_wrappers">
							<van-tabs @click="handleChangeVanTab($event)" v-model="active" :swipeable="false">
								<van-tab v-for="(item,index) in phoneTab" :title="index==0 ? item.title:item.title+'('+item.num+')'" :key='item.id'></van-tab>
							</van-tabs>
						</div>
						<div class="xs_tab_category" id="xs_list_wrappers">
							<van-tabs @click="handleChangeIndustry" v-model="activec">
								<van-tab v-for="(item,index) in industryList" :name="index==0 ? '':item.contprojIndustry" :title="index==0 ? item.contprojIndustry:item.contprojIndustry+'(未评分'+item.noCnt+')'" :key='item.contprojIndustry'>
									<van-pull-refresh v-model="refreshing"  @refresh="onRefresh">
										<van-list
											v-model="loading"
											:finished="finished"
											:finished-text="finishedText"
											@load="onLoad"
											:error.sync="error"
											error-text="请求失败，点击重新加载">
											<!-- <van-cell v-for="item in list" :key="item.contprojId" :title="item.contprojName" /> -->
											<template v-for="(item,index) in industryList">
												<div v-if="index>0 && zbProjList[item.contprojIndustry]" style="font-size: 25px; text-align: center; background-color: #1989FA; padding: 10px 0 10px 0; margin-top:5px; color: #fff">{{item.contprojIndustry}}</div>

												<div v-if="index>0 && zbProjList[item.contprojIndustry]" class="data_table">
													<div class="mytable_header">
														<div class="xs">序号</div>
														<div class="sm">名称</div>
														<!-- <div class="sm">组别</div> -->
														<!-- <div class="sm">类别</div> -->
														<div class="xs">分数</div>
														<div class="xs">操作</div>
													</div>
													<div class="my_table_body"  v-for="(item,index) in zbProjList[item.contprojIndustry]" :key="item.contprojId">
														<div class="xs">{{index+1}}</div>
														<div class="sm">{{item.contprojName}}</div>
														<!-- <div class="sm">{{item.contprojGroup}}</div> -->
														<!-- <div class="sm">{{item.contprojIndustry}}</div> -->
														<div class="xs">{{item.stagejudgeScore == null ? '--': item.stagejudgeScore}}</div>
														<div class="xs" style="text-overflow: inherit;">
															<van-button type="primary" size="mini" v-if="item.stagejudgeScore && item.stagejudgeScore!=0" @click="goDetail(index,item, currentPage)">查看</van-button>
															<van-button type="info" size="mini" v-else @click="goDetail(index,item, currentPage)">评分</van-button>
														</div>
													</div>
													
												</div>
											</template>
												
										</van-list>
									</van-pull-refresh>
								</van-tab>
							</van-tabs>
							<div class="alert" v-if="projList.length!=nowList.length">
								<div v-if="projList.length-nowList.length>0" style="font-size: medium;color: black;font-weight: 500;">未出现项目还有：
									<span style="color: red;">{{projList.length-nowList.length}}</span> 个
								</div>
								<div v-else style="font-size: medium;">所有项目已全部展示
								</div>
							</div>
						</div>
						<!-- 移动端评审须知 -->
						<van-popup v-model="phoneJudgeMaskRule" style="width:80%;padding:.4rem;margin:0 auto" :close-on-click-overlay="false">
							<div class="rule_wrap">
								<div class="rule_wrap_title">评审须知</div>
								<div v-html="stageInfo.stageRemark" class="rule_content"></div>
								<p style="color: red;font-size:.3rem;text-align: center;margin-top:.2rem">本人已熟知“评委审前须知”并同意“评审承诺”</p>
								<div style="text-align: center;margin-top:.2rem">
									<van-button type="info" size="small" @click="phoneStartps">开始评审</van-button>
								</div>
							</div>
						</van-popup>
						<van-popup v-model="phoneCheckConfirmMenuList" style="height:100vh;width:100vw" closeable>
								<div class="xs_check_box">
									<p style="font-size:.4rem;margin:1.8rem 0 .5rem;text-align:center;font-weight:700">已提交的确认单</p>
									<div  v-for="(item,index) in confirmList" :key="index" style="margin-bottom:.3rem">
										<img :src="$store.state.baseHost + '/' + item"  alt="" style="width:100%;height:100%">
									</div>
								</div>
						</van-popup>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import navBar from "@/components/navBar";
	import bottomBar from "@/components/bottomBar";
	import { isWeiXin, isMobile } from '../utils/userAgent.js'
	import { finished } from 'stream';
	import { log } from 'util';
	import $ from 'jquery' //在需要使用的页面中
	export default {
		components: {
			navBar,
			bottomBar,
		},
		name: 'Pingshen_list',
		data() {
			return {
				orgCover: null,
				defaultBannerImg: 'this.src="' + require("../assets/banner.jpg") + '"',
				defaultImg: 'this.src="' + require("../assets/image404.gif") + '"',

				stageInfo: [], //赛事环节信息
				contestId: null, //赛事id
				stageId: null, //环节id

				showSeeConfirm: false, //查看确认单按钮
				showConfirmList: false, //查看确认单弹层
				confirmList: [], //确认单列表

				countDownTime: null,
				saishiCountDownShow: false, //是否展示倒计时

				showMask: false, //打开评审须知
				showRules: false,

				showConfirmationBtn: false, //确认单按钮 默认隐藏，如果需要评审确认则在获取环节信息时处理
				showConfirmation: false, //确认单

				currentNav: 0, //标记 0-全部，1-已评审，2-未评审
				stagejudgeStatus: '', //环节评审状态：空值-全部；0-未评；1-已评
				currentIn: 0, //类别标记
				searchName:'',   //搜索名称
				contprojIndustry: '', //类别筛选

				currentPage: this.$route.query.currentPage==null || this.$route.query.currentPage=='' ? 1: Number(this.$route.query.currentPage), //初始页
				pagesize: 100, //    每页的数据
				total: 0, //总数

				projList: [], //项目列表
				zbProjList: [], //展示的项目列表
				industryList:[], //类别集合
				nowList: [], //缓存当前展示项目

				isCanConfirm: true, //是否可以提交确认单

				// 移动端当前页码
				phoneCurrentPage: this.$route.query.currentPage==null || this.$route.query.currentPage=='' ? 1: Number(this.$route.query.currentPage),
				phonepageSize: 100,
				phoneTotal: 0,
				finishedText: '没有更多了',
				phoneStagejudgeStatus: '', // 可选	环节评审状态：空值-全部；0-未评；1-已评（移动端）

				phoneTab: [], // 移动端评审状态tab
				active:Number(localStorage.getItem('activeIdx'))?Number(localStorage.getItem('activeIdx')):0, // 移动端评审状态标记
				activec:Number(localStorage.getItem('activecIdx'))?Number(localStorage.getItem('activecIdx')):0, // 移动端项目类别标记
				phoneCheckConfirmMenuList: false, // 查看确认单
				phoneJudgeMaskRule: false, // 移动端评审须知
				phoneFirstBoo: true, //移动端首次加载
				homeTop: 0,
				curScrollTop: 0, //记录滚动条位置对象
				// 移动端列表loading
				loading: false,
				// 移动端列表是否全部加载完
				finished: false,
				refreshing: false, // 下拉刷新
				error: false,
			}
		},
		watch: {
			zbProjList:function(){
				this.$nextTick(function(){
					// let scrollT = sessionStorage.getItem('scrollT');
					// document.documentElement.scrollTop = scrollT;
					// setTimeout(() => {
					// 	let scrollT = sessionStorage.getItem('scrollT');
					// 	document.documentElement.scrollTop = scrollT;
					// }, 150)
					if(sessionStorage.getItem('sc')){
						$('body,html').animate({
							scrollTop: sessionStorage.getItem('sc')
						},200);
						sessionStorage.removeItem('sc')
					}
				})
			}
		},
		methods: {
			//获取评委指定赛事环节信息
			getData() {
				this.$post("/api/stage/getJudgerStage", {
					judgerId: this.$store.state.userInfo.judeger.judgerId,
					stageId: this.stageId
				}).then((res) => {
					if (res.result) {
						this.stageInfo = res.resultData;

						this.phoneTab = [
							{
								id: 1,
								title: '全部',
								num: ''
							},
							{
								id: 2,
								title: '已评审',
								num: this.stageInfo.contprojOkCnt
							},
							{
								id: 3,
								title: '未评审',
								num: this.stageInfo.contprojTotalCnt-this.stageInfo.contprojOkCnt
							}
						];
						if (res.resultData.stageStatus != 0) {
							this.saishiCountDownShow = true;
							if (res.resultData.stageEndtime != null) {
								this.countDown(res.resultData.stageEndtime)
							}
						}

						if (res.resultData.contprojOkCnt < res.resultData.contprojTotalCnt) { //项目未评审完
							this.isCanConfirm = false; //是否可以提交确认单
							
							// 项目未评审完情况下，判断缓存里是否有rules，没有则弹出评审须知
							if (sessionStorage.getItem("rules"+this.stageId) == null && this.showSeeConfirm == false) {
								if(isWeiXin() || isMobile()){
									this.showPhoneRulesMask()
								} else {
									this.ShowRulesMask();
								}
							}
						}

						if (res.resultData.stageJudgeConfirm == 1) {
							this.showConfirmationBtn = true
						}

						//全部项目评审完毕，未上传过确认单，即直接弹出上传确认单界面
						if ((res.resultData.contprojOkCnt == res.resultData.contprojTotalCnt) && 
								(res.resultData.stageJudgeConfirm == 1) && (res.resultData.stageStatus == 1) && 
								(!this.showSeeConfirm)) {
							if(isWeiXin() || isMobile()) {
								this.phoneShowConfirmAction()
							} else {
								this.ShowConfirmationMask();
							}
						}
					} else {
						if(isWeiXin() || isMobile()){
							this.$toast.fail(res.resultMsg);
						} else {
							this.$message.error(res.resultMsg)
						}
					}
					if(!isWeiXin() && !isMobile()){
						this.getProjData();
					}
					this.queryIndustry();
				}).catch((res) => {
					console.log(res);
				})
			},
			// 获取评委确认信息
			getConfirm() {
				this.$post("/api/stage/queryConfirm", {
					stageId: this.stageId, //	number	必填	环节ID	
					judgerId: this.$store.state.userInfo.judeger.judgerId, //	number	必填	评委ID
				}).then((res) => {
					if (res.result) {
						if (res.resultData.confirmStatus == 1) {
							this.showSeeConfirm = true;
							this.confirmList = res.resultData.confirmPaths;
						} else {
							this.showSeeConfirm = false;
						}

					} else {
						this.showSeeConfirm = false;
					}
					this.getData();
				}).catch((res) => {
					console.log(res);
				})
			},
			//获取项目数据
			getProjData() {
				this.$post("/api/stage/listJudge", {
					contestId: this.contestId, //	number	必填	大赛ID	
					judgerId: this.$store.state.userInfo.judeger.judgerId, //	number	必填	评委ID	
					stageId: this.stageId, //	number	可选	环节ID	
					stageType: 0, //	number	可选	环节类型：0-网评；1-现场	
					stagejudgeStatus: this.stagejudgeStatus, //	number	可选	环节评审状态：空值-全部；0-未评；1-已评	
					contprojName: this.searchName, //	string	可选	项目名称
					contprojIndustry: this.contprojIndustry,
					currentPage: this.currentPage, //	number	必填	当前页码，从1开始
					pageSize: this.pagesize, //	number	必填	每页数量
				}).then((res) => {
					if (res.result) {
						this.projList = res.resultData.rows;
						this.total = res.resultData.total;
						this.getNowData(this.projList);
						//滚动条回到记录位置
						// let scrollT = sessionStorage.getItem('scrollT')
						// if (scrollT) {
						// 	setTimeout(() => {
						// 		document.documentElement.scrollTop = scrollT;
						// 	}, 100)
						// }
					}
				}).catch((res) => {
					console.log(res);
				})
			},
			//获取展示数据
			getNowData(data) {
				if(this.stageInfo.stageIsai==1){ //智能出项目
					var bacounts = this.stageInfo.stageBacount.split(',');
					var nowData = [];
					for(var i=0;i<data.length;i++){
						if(data[i].stagejudgeScore && data[i].stagejudgeScore!=0){ //将已评审的数据添加
							nowData.push(data[i]);
						}
					}
					if(nowData.length<bacounts[0]){
						nowData.push(...data.slice(nowData.length, bacounts[0]));
					}else{
						var reNum = (nowData.length-bacounts[0]) % bacounts[1];
						nowData.push(...data.slice(nowData.length, nowData.length+parseInt(bacounts[1])-parseInt(reNum)))
					}

					this.nowList = nowData;
					
					this.getLoopProj(nowData);
				}else{
					this.nowList = data;
					this.getLoopProj(data);
				}
          	},
			getLoopProj(data){
				var zbNameList = [];
				for(var i=0;i<data.length;i++){
					var dataZ = data[i];
					if(!zbNameList.includes(dataZ.contprojIndustry)){
						zbNameList.push(dataZ.contprojIndustry);
					}
				}
				this.zbProjList = {};
				for(var i=0;i<zbNameList.length;i++){
					var pList = [];
					for(var l=0;l<data.length;l++){
						var dataZ = data[l];
						if(dataZ.contprojIndustry==zbNameList[i]){
							pList.push(dataZ);
						}
					}
					this.zbProjList[zbNameList[i]] = pList;
				}

				//滚动条回到记录位置
				// let scrollT = sessionStorage.getItem('scrollT')
				// if (scrollT) {
				// 	setTimeout(() => {
				// 		document.documentElement.scrollTop = scrollT;
				// 	}, 500)
				// }

				if(this.stageInfo.stageIsai==1 && this.projList.length!=this.nowList.length){
					$('.van-tabs__line').css('display','none');
					this.finishedText='';
				}else{
					$('.van-tabs__line').css('display','');
				}
			},
			//获取项目类别
			async queryIndustry(){
				try {
					const res = await this.$post("/api/stage/queryIndustry", {
						contestId: this.contestId, //	number	必填	大赛ID
						judgerId: this.$store.state.userInfo.judeger.judgerId, //	number	必填	评委ID	
						stageId: this.stageId //	number	可选	环节ID
					})
					if(res.result){
						this.industryList = [
							{
								contprojIndustry: '全部',
								noCnt: 0
							}
						];
						for(var i=0;i<res.resultData.length;i++){
							this.industryList.push(res.resultData[i]);
						}
					} else {
						this.$toast.fail(res.resultMsg);
					}
				} catch(error){
					console.log(error);
					this.error = true;
				} finally {

				}
			},
			//
			isaiFun(){
				if(this.stageInfo.stageIsai==1 && this.projList.length!=this.nowList.length){
					var bacounts = this.stageInfo.stageBacount.split(',');
					this.$message.warning("本次评审采用智能评审规则（优先评审"+bacounts[0]+"个项目，随后"+bacounts[1]+"个"+bacounts[1]+"个项目进行评审），该筛选功能暂时不能使用");
					return true;
				}
				return false;
			},
			//项目搜索
			search(){
				if(this.isaiFun()){
					return;
				}
				this.resetPage();
				this.getProjData();
			},
			//筛选评审状态
			changePSType(type, mark) {
				if(this.isaiFun()){
					return;
				}
				this.active =type;
				this.currentNav = type;
				if (type == 0) {
					this.stagejudgeStatus = '';
					this.phoneStagejudgeStatus = '';
					this.active =0;
				} else if (type == 1) {
					this.stagejudgeStatus = 1;
					this.phoneStagejudgeStatus = 1;
				} else if (type == 2) {
					this.stagejudgeStatus = 0;
					this.phoneStagejudgeStatus = 0;
				}
				if(mark==0){
					this.currentPage=this.$route.query.currentPage==null || this.$route.query.currentPage=='' ? 1: Number(this.$route.query.currentPage);
					this.phoneCurrentPage=this.$route.query.currentPage==null || this.$route.query.currentPage=='' ? 1: Number(this.$route.query.currentPage);
				}else{
					this.resetPage();
				}
				this.getProjData()
			},
			//移动端筛选评审状态
			handleChangeVanTab(num){
				if(this.isaiFun()){
					return;
				}
				this.active =num;
				this.currentNav=num;
   				localStorage.setItem('activeIdx',num);
				if(num==0) {
					this.stagejudgeStatus = '';
					this.phoneStagejudgeStatus = '';
				} else if(num==1){
					this.stagejudgeStatus = 1;
					this.phoneStagejudgeStatus = 1;
				} else if(num==2){
					this.stagejudgeStatus = 0;
					this.phoneStagejudgeStatus = 0;
				}
				this.resetPage();
				this.phoneFirstBoo = true;
				this.projList = [];
				this.onLoad();
			},
			//筛选项目类别
			changeIndustry(type, industry){
				if(this.isaiFun()){
					return;
				}
				this.currentIn = type;
				this.contprojIndustry = industry;
				this.resetPage();
				this.getProjData();
			},
			//移动端筛选项目类别
			handleChangeIndustry(tab, title){
				if(this.isaiFun()){
					return;
				}
				this.resetPage();
				this.contprojIndustry = tab;
				this.phoneFirstBoo = true;
				this.projList = [];
				this.onLoad();
			},
			// 分页长度触发事件
			handleSizeChange: function(size) {
				this.pagesize = size;
				this.getProjData();
			},
			// 分页切换，点击第几页
			handleCurrentChange: function(currentPage) {
				this.currentPage = currentPage;
				this.getProjData();
			},
			//重置分页
			resetPage(){
				this.currentPage=1;
				this.phoneCurrentPage=1;
			},
			//进入打分页
			goDetail(index, row, currentPage) {
				localStorage.setItem("scroll", document.documentElement.scrollTop)
				sessionStorage.setItem("nowList"+row.stageId, JSON.stringify(this.nowList))
				// debugger
				this.$router.push({
					name: 'pingshen_detail',
					query: {
						contprojId: row.contprojId,
						stageId: row.stageId,
						stagejudgeId: row.stagejudgeId,
						stageJudgeConfirm: this.stageInfo.stageJudgeConfirm,
						type: this.currentNav,
						index:index,
						currentPage: currentPage
					}
				})
			},
			//打开评审须知
			ShowRulesMask() {
				this.showMask = !this.showMask;
				this.showRules = !this.showRules;
				if(this.showRules==false && this.showMask==false){
					sessionStorage.setItem("rules"+this.stageId, "1")
				}
			},
			// 移动端打开评审须知
			showPhoneRulesMask(){
				this.phoneJudgeMaskRule = true
			},
			// 移动端开始评审
			phoneStartps(){
				this.phoneJudgeMaskRule = false
				if(this.phoneJudgeMaskRule== false){
					sessionStorage.setItem("rules"+this.stageId, "1")
				}
			},
			//点击签署确认单按钮
			ShowConfirmationMask() {
				if (!this.isCanConfirm) {
					this.$message.warning('项目没有评审完毕');
					return;
				}
				this.$confirm('根据大赛要求，评审完后需要签署确认单，是否确定签署确认单？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.push({
						name: 'pingshen_queren',
						query: {
							stageId: this.stageId,
							contestId: this.contestId
						}
					});
				}).catch(() => {});
			},
			// 移动端点击签署确认单按钮
			phoneShowConfirmAction(){
				if (!this.isCanConfirm) {
					this.$toast.fail('项目没有评审完毕');
					return;
				}
				this.$dialog.confirm({
					title: '提示',
					message: '根据大赛要求，评审完后需要签署确认单，是否确定签署确认单？',
				})
				.then(() => {
					// on confirm
					this.$router.push({
					name: 'pingshen_queren',
					query: {
						stageId: this.stageId,
						contestId: this.contestId
					}
					})
				})
				.catch(() => {
					// on cancel
				});
			},
			//移动端加载
			onLoad() {
				if(this.phoneFirstBoo){ //移动端首次加载
					this.phoneFirstBoo = false;
				}else{
					this.phoneCurrentPage++;
				}
			 	this.getListMore()
			},
			//移动端刷新加载
			onRefresh() {
				this.resetPage();
				this.getListMore();
			},
			async getListMore(){
				try {
					const res = await this.$post("/api/stage/listJudge", {
						contestId: this.contestId, //	number	必填	大赛ID	
						judgerId: this.$store.state.userInfo.judeger.judgerId, //	number	必填	评委ID	
						stageId: this.stageId, //	number	可选	环节ID	
						stageType: 0, //	number	可选	环节类型：0-网评；1-现场	
						stagejudgeStatus: this.phoneStagejudgeStatus, //	number	可选	环节评审状态：空值-全部；0-未评；1-已评	
						contprojIndustry: this.contprojIndustry,
						contprojName: this.searchName, //	string	可选	项目名称
						currentPage: this.phoneCurrentPage, //	number	必填	当前页码，从1开始
						pageSize: this.phonepageSize, //	number	必填	每页数量
					})
					if(res.result){
						this.loading = false;
						this.projList = [...this.projList,...res.resultData.rows];
						if(this.phoneCurrentPage*this.phonepageSize>=res.resultData.total){
							this.finished = true;
							this.finishedText = "没有更多了";
						}
						this.getNowData(this.projList);
						
						
					} else {
						this.$toast.fail(res.resultMsg);
					}
				} catch(error){
					console.log(error);
					this.error = true;
				} finally {

				}
			},
			// 完成率百分比计算
			getnum(yp, total) {
				let percetage = yp / total;
				if (percetage > 0) {
					let aNew;
					let re = /([0-9]+\.[0-9]{4})[0-9]*/;
					aNew = percetage.toString().replace(re, "$1");
					return (Math.round(aNew * 10000) / 100).toFixed(2) + '%';
				} else {
					return '0' + '%'
				}
			},
			// 倒计时
			addZero(i) {
				return i < 10 ? "0" + i : i + "";
			},
			countDown(time) {
				var nowtime = new Date();
				var endtime = new Date(time.replace(/-/g, '/'));
				var lefttime = parseInt((endtime.getTime() - nowtime.getTime()) / 1000);
				var d = parseInt(lefttime / (24 * 60 * 60))
				var h = parseInt(lefttime / (60 * 60) % 24);
				var m = parseInt(lefttime / 60 % 60);
				var s = parseInt(lefttime % 60);
				d = this.addZero(d);
				h = this.addZero(h);
				m = this.addZero(m);
				s = this.addZero(s);
				// $('#countDown').html(`${d}天 ${h} 时 ${m} 分 ${s} 秒`);
				this.countDownTime = `${d}天 ${h} 时 ${m} 分 ${s} 秒`;
				if (lefttime <= 0) {
					this.countDownTime = '评审已结束';
					this.saishiCountDownShow = false;
					return;
				}
				let timer = setTimeout(() => {
					this.countDown(time);
				}, 1000)
				this.$once("hook:beforeDestroy", () => {
					clearInterval(timer);
				});
			},
			//查看确认单按钮
			showConfirmListFN() {
				this.showMask = !this.showMask;
				this.showConfirmList = !this.showConfirmList;
			},
			//返回评委个人中心
			goCenter() {
				sessionStorage.removeItem('rules'+this.stageId);
				this.$router.push({
					name: 'pingshen_index'
				});
			},
			//刷新
			reload() {
				location.reload();
			},
		},
		mounted() {
			if (this.$route.query.stageId && this.$route.query.contestId) {
				this.stageId = this.$route.query.stageId;
				this.contestId = this.$route.query.contestId;
				this.getConfirm();
			} else {
				if(isWeiXin() || isMobile()){
					this.$toast.fail('缺少环节id');
				} else {
					this.$message.error('缺少环节id')
				}
				
			}
			// $('body,html').animate({
			// 	scrollTop: sessionStorage.getItem('sc')
			// },500);
			// sessionStorage.removeItem('sc')
		},
		created() {
			if (this.$store.state.orgData) {
				this.orgCover = this.$store.state.orgData.detail.orgCover;
			}
		},
		beforeRouteLeave (to, from, next) {
			sessionStorage.setItem('sc',document.documentElement.scrollTop)
			this.curScrollTop = document.documentElement.scrollTop 
			next()
		},
	}
</script>


<style scoped lang='less'>
	.main {
		width: 100%;
		height: auto;
		min-height: 92px;
		background: #e8edf3 url('../assets/pingshen-bg.png') no-repeat top center;
		margin: 0 auto;
		min-width: 1200px;
		padding-bottom: 30px;
	}

	.main .ps-title {
		width: 1200px;
		height: 60px;
		line-height: 60px;
		margin: 0 auto;
		color: #fff;
		font-size: 20px;
	}

	.main .ps-title a {
		color: #fff;
	}

	.container {
		width: 1200px;
		margin: 0 auto;
		background: #fff;
		height: auto;
		padding: 38px 66px;
		box-sizing: border-box;
	}

	.container .mainBox {
		width: 100%;
		margin: 0 auto;
	}


	.container .mainBox .saishi-box {
		width: 100%;
		height: 225px;
		display: flex;
		margin-bottom: 90px;
	}

	.container .mainBox .saishi-pic {
		width: 395px;
		height: 226px;
	}

	.container .mainBox .saishi-pic img {
		width: 395px;
		height: 226px;
	}

	.container .mainBox .saishi-info {
		margin-left: 24px;
	}

	.container .mainBox .saishi-title {
		font-size: 18px;
		color: #000;
		width: 100%;
		height: 35px;
		line-height: 35px;
		font-weight: 700;
		overflow: hidden;
	}

	.container .mainBox .saishi-title .t1 {
		width: 510px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
	}

	.container .mainBox .saishi-title button {
		float: right;
		height: 35px;
		padding: 0 25px;
		cursor: pointer;
	}

	.container .mainBox .saishi-type {
		width: 100%;
		height: 25px;
		display: flex;
		align-items: center;
		margin: 15px 0;
	}

	.container .mainBox .saishi-type .saishi-type-blue {
		width: auto;
		height: 22px;
		line-height: 22px;
		text-align: center;
		padding: 0 10px;
		color: #fff;
		font-size: 14px;
		background: #1586f7;
	}

	.container .mainBox .saishi-type .saishi-type-orange {
		width: auto;
		height: 22px;
		line-height: 22px;
		text-align: center;
		padding: 0 10px 0 20px;
		color: #fff;
		font-size: 14px;
		background: #f45700;
		position: relative;
		margin-left: 20px;
	}

	.container .mainBox .saishi-type .saishi-type-orange.yjs {
		background: gray;
		color: #fff;
	}

	.container .mainBox .saishi-type .saishi-type-orange.wks {
		background: #5cb85c;
		color: #fff;
	}


	.container .mainBox .saishi-type .saishi-type-orange::before {
		content: "";
		width: 0;
		height: 0;
		border-top: 11px solid transparent;
		border-left: 10px solid #fff;
		border-bottom: 11px solid transparent;
		position: absolute;
		left: 0;
		top: 0;
	}

	.container .mainBox .saishi-time {
		width: 100%;
		height: 35px;
		line-height: 35px;
		font-size: 16px;
		color: #4b4848;
	}

	.container .mainBox .saishi-proccess {
		width: 100%;
		height: 35px;
		display: flex;
		align-items: center;
	}

	.container .mainBox .saishi-proccess div {
		margin-left: 80px;
		font-size: 14px;
		color: #222121;
	}

	.container .mainBox .saishi-proccess div.saishi-proccess-yp {
		margin-left: 0;
	}

	.container .mainBox .saishi-btn-box {
		height: 55px;
		width: 670px;
		display: flex;
		align-items: center;
		margin-top: 10px;
	}

	.container .mainBox .saishi-btn-box .saishi-countdown {
		width: auto;
		height: 32px;
		line-height: 32px;
		padding: 0 15px;
		color: #6e716f;
		font-size: 14px;
		border-radius: 4px;
		overflow: hidden;
		border: 2px solid #fbd17c;
		box-sizing: content-box;
		// display: flex
	}

	.container .mainBox .saishi-btn-box .saishi-countdown span {
		color: #050505;
		font-size: 18px;
		display: inline-block;
	}

	.container .mainBox .saishi-btn {
		width: auto;
		height: 32px;
		line-height: 32px;
		box-sizing: content-box;
		color: #1586f7;
		border-radius: 4px;
		border: 2px solid #1b99ed;
		margin-left: 22px;
		padding: 0 12px;
		cursor: pointer;
		-moz-user-select: none;
		/*火狐*/
		-webkit-user-select: none;
		/*webkit浏览器*/
		-ms-user-select: none;
		/*IE10*/
		-khtml-user-select: none;
		/*早期浏览器*/
		user-select: none;
	}

	.reload {
		width: auto;
		height: 30px;
		line-height: 30px;
		padding: 0 10px;
		border-radius: 4px;
		background: #f45700;
		color: #fff;
		margin-left: 10px;
		cursor: pointer;
	}

	.container .mainBox .p-navbar-box {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.container .mainBox .p-navbar {
		height: 42px;
		line-height: 42px;
		font-size: 16px;
		color: #000000;
		display: flex;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
	}

	.container .mainBox .p-navbar span {
		display: inline-block;
	}

	.container .mainBox .p-navbar .p-bar {
		flex: 1;
		display: flex;
		flex-wrap: nowrap;
		overflow: hidden;
		align-items: center;
	}

	.container .mainBox .p-navbar .p-bar .p-bar-item {
		width: auto;
		height: 26px;
		line-height: 26px;
		font-size: 14px;
		color: #000;
		text-align: center;
		border-radius: 4px;
		cursor: pointer;
		margin-left: 35px;
		padding: 0 13px;
		-moz-user-select: none;
		/*火狐*/
		-webkit-user-select: none;
		/*webkit浏览器*/
		-ms-user-select: none;
		/*IE10*/
		-khtml-user-select: none;
		/*早期浏览器*/
		user-select: none;
	}

	.container .mainBox .p-navbar .p-bar .p-bar-item.active {
		background: #038eeb;
		color: #fff;
		;
	}

	.container .mainBox .p-navbar .p-bar .p-bar-item:hover {
		background: #038eeb;
		color: #fff;
	}

	.container .mainBox .p-navbar .p-bar .search-content {
		/* height: 30px; */
		display: flex;
		/* margin-left: 35px; */
	}

	.container .mainBox .p-navbar .p-bar .search-content .search-inp {
		flex: 1;
		height: 28px;
		outline: none;
		background-color: transparent;
		border: 1px solid #e4e4e4;
		box-sizing: content-box;
		padding-left: 14px;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		font-size: 14px;
	}

	.container .mainBox .p-navbar .p-bar .search-content .search-btn {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		width: 45px;
		display: flex;
		align-items: center;
		border: none;
		outline: none;
		color: #fff;
		background: #038eeb url('../assets/search-icon.png') no-repeat center;
	}

	/* 确认单列表 */
	.confirmationList {
		width: 800px;
		height: 700px;
		background: #fff;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 100;
		overflow: hidden;
	}

	.confirmationList .title {
		width: 100%;
		height: 60px;
		line-height: 60px;
		text-align: center;
		font-size: 18px;
		color: #000000;
	}

	.confirmationList .list-box {
		width: 750px;
		height: 560px;
		margin: 0 auto;
		text-align: center;
		overflow: hidden;
		overflow-y: auto;
		display: block;
	}

	.confirmationList .list-box img {
		width: 100%;
		height: auto;
		display: block;
	}

	.confirmationList .close-small-btn {
		width: 25px;
		height: 25px;
		position: absolute;
		right: 20px;
		top: 20px;
		z-index: 101;
		background: url('../assets/close-2.png') no-repeat center;
		background-size: 50%;
		cursor: pointer;
	}

	.confirmationList .close-btn {
		width: 198px;
		height: 34px;
		line-height: 34px;
		background: #0795f4;
		color: #fff;
		margin: 0 auto;
		margin-top: 20px;
		text-align: center;
		font-size: 14px;
		outline: none;
		border: none;
		display: block;
		cursor: pointer;
	}


	.pd-list {
		width: 100%;
		height: auto;
		padding-top: 40px;
		overflow: hidden;
	}

	.pd-list .pd-item {
		width: 100%;
		height: 100px;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}

	.pd-list .pd-item .pd-img {
		width: 75px;
		height: 75px;
		position: relative;
	}

	.pd-list .pd-item .pd-img img {
		width: 75px;
		height: 75px;
		display: block;
	}

	.pd-list .pd-item .pd-info {
		width: 650px;
		height: 100px;
		overflow: hidden;
		margin-left: 20px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.pd-list .pd-item .pd-info .pd-info-title {
		width: 100%;
		height: 35px;
		line-height: 35px;
		font-size: 18px;
		color: #000000;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.pd-list .pd-item .pd-info .pd-info-title .pd-info-city {
		width: auto;
		height: 20px;
		line-height: 20px;
		box-sizing: content-box;
		font-size: 14px;
		color: #000;
		margin-left: 5px;
		border: 1px solid #038eeb;
		border-radius: 4px;
		padding: 0 6px;
		-moz-user-select: none;
		/*火狐*/
		-webkit-user-select: none;
		/*webkit浏览器*/
		-ms-user-select: none;
		/*IE10*/
		-khtml-user-select: none;
		/*早期浏览器*/
		user-select: none;
	}

	.pd-list .pd-item .pd-info .pd-info-category {
		width: 100%;
		height: 30px;
		line-height: 30px;
		font-size: 14px;
		color: #000000;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.pd-list .pd-item .pd-info-fraction-box {
		width: 130px;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: #f45700;
	}

	.pd-list .pd-item .pd-info-fraction-box .pd-info-fraction {
		font-size: 24px;
	}

	.pd-list .pd-item .pd-info-fraction-box .pd-info-fraction-p {
		font-size: 16px;
	}

	.pd-list .pd-item .pd-btn {
		display: flex;
		justify-content: flex-end;
		flex: 1;
	}

	.pd-list .pd-item .pd-btn a {
		width: auto;
		height: 34px;
		line-height: 34px;
		padding: 0 13px;
		background: #fff;
		border: 1px solid #038eeb;
		color: #038eeb;
		border-radius: 4px;
	}



	.page-pag {
		width: 100%;
		height: 34px;
		line-height: 34px;
		margin: 0 auto;
		display: flex;
		justify-content: flex-end;
		font-size: 14px;
	}

	.page-pag div {
		display: inline-block;
	}

	.page-pag .count {
		margin-left: 20px;
	}

	.page-pag .totals div {
		width: auto;
		height: 34px;
		line-height: 34px;
		display: inline-block;
		text-align: center;
		cursor: pointer;
		border: 1px solid #ddd;
		color: #3096ea;
		padding: 0 13px;
	}

	.page-pag .prev-btn,
	.page-pag .next-btn {
		width: auto;
		height: 34px;
		line-height: 34px;
		display: inline-block;
		text-align: center;
		cursor: pointer;
		border: 1px solid #ddd;
		color: #3096ea;
		padding: 0 13px;
	}

	.page-pag .totals div.active {
		background: #3096ea;
		border-color: #3096ea;
		color: #fff;
		-moz-user-select: none;
		/*火狐*/
		-webkit-user-select: none;
		/*webkit浏览器*/
		-ms-user-select: none;
		/*IE10*/
		-khtml-user-select: none;
		/*早期浏览器*/
		user-select: none;
	}

	.page-pag .totals div:hover,
	.page-pag .prev-btn:hover,
	.page-pag .next-btn:hover {
		background: #3096ea;
		border-color: #3096ea;
		color: #fff;
	}

	button:focus {
		outline: none;
	}










	.mask {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(114, 114, 114, 0.8);
		z-index: 99;
	}


	.rules {
		width: 720px;
		height: 570px;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 100;
		background: #fff;
	}

	.rules .rule-title {
		width: 100%;
		height: 70px;
		line-height: 70px;
		text-align: center;
		font-size: 18px;
		color: #000;
		font-weight: 600;
	}

	.rules .rule-content {
		width: 620px;
		height: 400px;
		overflow: hidden;
		overflow-y: auto;
		background: #d8d8d8;
		margin: 0 auto;
		padding: 20px;
		box-sizing: border-box;
	}

	.rules .rule-tips {
		width: 100%;
		height: 50px;
		line-height: 50px;
		text-align: center;
		font-size: 12px;
		color: #9ea0a1;
	}

	.rules .rule-confirm {
		width: 200px;
		height: 32px;
		line-height: 32px;
		text-align: center;
		margin: 0 auto;
		color: #fff;
		background: #038eeb;
		font-size: 14px;
		border-radius: 4px;
		-moz-user-select: none;
		/*火狐*/
		-webkit-user-select: none;
		/*webkit浏览器*/
		-ms-user-select: none;
		/*IE10*/
		-khtml-user-select: none;
		/*早期浏览器*/
		user-select: none;
		cursor: pointer;
	}


	.confirmation {
		width: 654px;
		height: 467px;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 100;
		background: #fff;
	}

	.confirmation .close-btn {
		width: 25px;
		height: 25px;
		position: absolute;
		right: 20px;
		top: 20px;
		z-index: 101;
		background: url('../assets/close-2.png') no-repeat center;
		background-size: 50%;
		cursor: pointer;
	}

	.confirmation .confirmation-title {
		width: 100%;
		height: 60px;
		line-height: 60px;
		text-align: center;
		font-size: 18px;
		color: #000000;
	}

	.confirmation .confirmation-content {
		width: 550px;
		height: 300px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		margin-top: 30px;
	}

	.confirmation .confirmation-content .box {
		height: 230px;
		display: flex;
		flex-direction: column;
		flex: 1;
		align-items: center;
		max-width: 300px;
	}

	.confirmation .confirmation-content .box .name {
		font-size: 14px;
		color: #000000;
	}

	.confirmation .confirmation-content .box .name .num {
		width: 26px;
		height: 26px;
		line-height: 26px;
		border-radius: 50%;
		border: 1px solid #0c92ec;
		color: #000;
		text-align: center;
		display: inline-block;
		margin-right: 10px;
	}

	.confirmation .confirmation-content .hr {
		height: 82px;
		border-right: 1px dashed #000;
		margin-right: 30px;
	}

	.confirmation .confirmation-content .box .icon1 {
		width: 26px;
		height: 33px;
		background: url('../assets/ps-pic1.png') no-repeat;
		background-size: contain;
		margin: 0 auto;
		margin-top: 40px;
	}

	.confirmation .confirmation-content .box .download-btn {
		width: auto;
		height: 30px;
		line-height: 30px;
		text-align: center;
		padding: 0 20px;
		border: 1px solid #bfc1c2;
		color: #000;
		border-radius: 4px;
		cursor: pointer;
		-moz-user-select: none;
		/*火狐*/
		-webkit-user-select: none;
		/*webkit浏览器*/
		-ms-user-select: none;
		/*IE10*/
		-khtml-user-select: none;
		/*早期浏览器*/
		user-select: none;
		cursor: pointer;
		margin-top: 30px;
	}

	.confirmation .confirmation-content .box .icon2 {
		width: 26px;
		height: 33px;
		background: url('../assets/ps-pic2.png') no-repeat;
		background-size: contain;
		margin: 0 auto;
		margin-top: 40px;
	}

	.confirmation .confirmation-content .box .form-box {
		display: flex;
		margin-top: 30px;
		width: 100%;
		text-align: center;
	}

	.confirmation .confirmation-content .box .form-box .reset {
		width: auto;
		height: 30px;
		line-height: 30px;
		text-align: center;
		padding: 0 20px;
		background: gray;
		border: 1px solid #bfc1c2;
		color: #fff;
		margin-left: 10px;
		border-radius: 4px;
		cursor: pointer;
		display: none;
	}

	.confirmation .confirmation-content .box .upload-btn {
		display: block;
		position: relative;
		width: auto;
		height: 30px;
		line-height: 30px;
		text-align: center;
		padding: 0 20px;
		background: #0c92ec;
		border: 1px solid #bfc1c2;
		color: #fff;
		border-radius: 4px;
		cursor: pointer;
		-moz-user-select: none;
		/*火狐*/
		-webkit-user-select: none;
		/*webkit浏览器*/
		-ms-user-select: none;
		/*IE10*/
		-khtml-user-select: none;
		/*早期浏览器*/
		user-select: none;
		cursor: pointer;
	}

	.confirmation .confirmation-content .box .upload-btn input {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0
	}

	.confirmation .confirmation-btn {
		width: 198px;
		margin: 0 auto;
		margin-top: 20px;
		display: block;
	}

	.qianmingBtn {
		width: auto;
		height: 30px;
		line-height: 30px;
		text-align: center;
		padding: 0 20px;
		border: 1px solid #bfc1c2;
		color: #fff;
		border-radius: 4px;
		cursor: pointer;
		background: #1B99ED;
		-moz-user-select: none;
		/*火狐*/
		-webkit-user-select: none;
		/*webkit浏览器*/
		-ms-user-select: none;
		/*IE10*/
		-khtml-user-select: none;
		/*早期浏览器*/
		user-select: none;
		cursor: pointer;
		margin-top: 30px;
	}
	.alert{
		width: 100%;
    padding: 8px 16px;
    margin: 0;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
    background-color: #FFF;
    overflow: hidden;
    opacity: 1;
    display: flex;
    align-items: center;
    transition: opacity .2s;
	background-color: #f4f4f5;
    color: #909399;
	justify-content: center;
	font-size: medium;
	}

	/* --------------移动端样式------------------ */
	.phone_banner {
		height: 4rem;
	}

	.xs_main {
		width: 100%;
		height: auto;
		/* padding: 0 .2rem; */
		box-sizing: border-box;
	}

	.xs_ps_wrap {
		background: #e8edf3;
		padding-bottom: .5rem
	}

	.xs_ps_title {
		font-size: 0.4rem;
		color: #fff;
		padding: 0.3rem 0.2rem 0;
		height: 2rem;
		/* padding-bottom: .2rem; */
		background: url(../assets/pingshen-bg.png) no-repeat top;
		background-size: 100% 100%;
	}

	.xs_ps_main {
		background: #fff;
		/* padding: 0.3rem; */
		margin: 0 0.2rem;
		margin-top: -1rem;
		/* display: flex;
  align-items: center;
  overflow: hidden; */
	}
	/* .xs_tab_category /deep/ .van-tabs__line {
		background-color: #038eeb;
	} */
	
	.activity_item_wrapper {
		padding: 0.4rem;
	}
	
	.activity_item_wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	// .activity_item_left {}
	
	.activeity_item_img_wrap {
		width: 3rem;
		height: 2rem;
		position: relative;
	}
	
	.activeity_item_img_wrap img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	
	.activity_item_center {
		padding: 0 0.4rem;
		flex: 1;
		width: 2rem;
		/* width: 200px; */
	}
	
	.item_title {
		font-size: 0.4rem;
	}
	
	.item_info {
		margin-top: 0.2rem;
	}
	
	.item_info p {
		color: #4b4848;
		font-size: 0.3rem;
	}
	
	.item_proccess {
		display: flex;
		justify-content: space-between;
		font-size: 0.3rem;
		margin-top: 0.2rem;
	}
	
/* 	.activity_item_right {
		width: 2rem;
	} */
	.xs_back {
		width: 40%;
		max-width: 4rem;
	}
	.xs_back button {
		margin: .4rem .4rem 0;
	}
	.tag_wrap {
		margin-top: .2rem;
		display: flex;
	}
	.phone-saishi-type {
		/* width: auto; */
		height: 22px;
		line-height: 22px;
		text-align: center;
		/* padding: 0 10px 0 20px; */
		color: #fff;
		font-size: 14px;
		background: #f45700;
		position: relative;
		/* padding: 2px 6px; */
		padding: 0 10px 0 20px;		
		font-size: 12px;
    	/* line-height: 16px; */
		margin-left: 20px
	}
	.phone-saishi-type::before {
		content: "";
		width: 0;
		height: 0;
		border-top: 11px solid transparent;
		border-left: 10px solid #fff;
		border-bottom: 11px solid transparent;
		position: absolute;
		left: 0;
		top: 0;
	}
	.phone-saishi-type-yjs {
		background: gray !important;
	}

	.phone-saishi-type-wks {
		background: #5cb85c !important;
	}
	.btn_group {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: .4rem
	}
	.btn_group > button {
		margin: .2rem 0;
	}
	.xs_tab_category /deep/ .van-tabs__nav--line.van-tabs__nav--complete {
		padding-left: 0;
	}
	.xs_tab_category /deep/ .van-tabs__line {
		background-color: #038eeb;
	}
	.van-search__action {
		line-height: normal;
	}
	.data_table {
		padding: .2rem .4rem;
	}
	.mytable_header {
		font-size: .4rem;
		color: #000;
		display: flex;
		text-align: center;
		font-weight: 700;
		border-bottom: 1px solid #eee;
		padding: .2rem;
	}
	.mytable_header > div {
		font-size: .4rem;
	}
	.my_table_body {
		font-size: .4rem;
		display: flex;
		text-align: center;
		align-items: center;
		border-bottom: 1px solid #f1f1f1;
		padding: .2rem;
	}
	.my_table_body > div {
		// margin: .2rem 0;
		font-size: .4rem;
		// padding: .2rem;
	}
	.xs {
		flex: .4;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.sm {
		flex: 1;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.md {
		flex: 2;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.lg {
		flex: 3
	}
	// .rule_wrap {
		// padding: .4rem;
		// width: 80%;
	// }
	.rule_content {
		background: #d8d8d8;
		font-size: .4rem;
		padding: .4rem;
		height: 5rem;
		overflow: auto;
	}
	.rule_wrap_title {
		font-weight: 700;
		font-size: .5rem;
		margin-bottom: .2rem;
		text-align: center
	}
	// .xs_check_box {、
		// margin: calc(100vh - (100vh - 1.3rem)) 0;
	// }
	.xs_main /deep/ .van-popup__close-icon--top-right {
		top: 2rem;
		font-size: .8rem
	}
	/* --------------移动端样式------------------ */
</style>
