import { render, staticRenderFns } from "./Roadshow_cj.vue?vue&type=template&id=226944ec&scoped=true"
import script from "./Roadshow_cj.vue?vue&type=script&lang=js"
export * from "./Roadshow_cj.vue?vue&type=script&lang=js"
import style0 from "./Roadshow_cj.vue?vue&type=style&index=0&id=226944ec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "226944ec",
  null
  
)

export default component.exports