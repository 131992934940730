<template>
    <div>
            <div v-if="this.isSowVideo">
              <video width="100%" height="100%" controls>
              <source :src="this.videoURL" type="video/mp4">
            </video>
            </div>

            <div class="showErr" v-if="!this.isSowVideo">
              {{this.noFileDesc}}
            </div>
    
    </div>
</template>

<script>
import navBar from "@/components/navzgBar";
import bottomBar from "@/components/bottomzgBar";
import XhyAutograph from "../../components/Autograph";
import { isWeiXin, isMobile } from "../../utils/userAgent.js";
export default {
  components: {
    navBar,
    bottomBar,
    XhyAutograph
  },
  data() {
    return {
      options: {
        penColor: "black", //笔刷颜色
        minWidth: 2 //最小宽度
      },
      noFileDesc:"",
      newFurl:null,
      videoURL: null, //视频播放路径
      isSowVideo:false,
      furl: this.$route.query.furl==null || this.$route.query.furl=='' ? 1: this.$route.query.furl, //初始页
      qmImgData64: ""
    };
  },
  methods: {
    getData() {
      this.$post("/api/contproj/openAttachMp4File",{
                    projattachFileUrl: this.furl
                }).then((res)=>{
                    if(res.result){
                        this.isSowVideo =true;
                        this.videoURL = res.resultData.openAttachFileUrl;
                    }else{
                        this.isSowVideo =false;
                        this.noFileDesc = "给定的furl参数的文件不存在。请求地址返回错误信息：远程服务器返回错误: (404) 未找到。 请检查您的文件下载地址，或联系我们";
                        if(isWeiXin() || isMobile()){
                               // this.$toast.fail(res.resultMsg);
                        } else {
                               // this.$message.error(res.resultMsg);
                        }
                    }
                }).catch((res)=>{
                    console.log(res);
                })   
    },


  },
  mounted() {
    this.getData();
     
  },
  created() {
    if (this.$store.state.orgData) {
      this.orgCover = this.$store.state.orgData.detail.orgCover;
    }
  }
};
</script>

<style scoped>
.main {
  width: 100%;
  height: auto;
  min-height: 92px;
  background: #e8edf3 url("../../assets/stucplan/pingshen-bg.png") no-repeat top center;
  margin: 0 auto;
  min-width: 1200px;
  padding-bottom: 30px;
}
.main .ps-title {
  width: 1200px;
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
  color: #fff;
  font-size: 20px;
}

.showErr {
  height: 159px;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 8px;
  font-size: 16px;
  font-family: 'Microsoft YaHei', '微软雅黑', sans-serif;
  width: 1904px;
}


.main .ps-title a {
  color: #fff;
}

.container {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  height: auto;
  padding: 38px 66px;
  box-sizing: border-box;
}
.container .mainBox {
  width: 100%;
  margin: 0 auto;
}

.container .mainBox .saishi-box {
  width: 100%;
  height: 225px;
  display: flex;
  margin-bottom: 90px;
}
.container .mainBox .saishi-pic {
  width: 395px;
  height: 226px;
}
.container .mainBox .saishi-pic img {
  width: 395px;
  height: 226px;
}
.container .mainBox .saishi-info {
  margin-left: 24px;
}
.container .mainBox .saishi-title {
  font-size: 18px;
  color: #000;
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-weight: 700;
  overflow: hidden;
}
.container .mainBox .saishi-title .t1 {
  width: 510px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.container .mainBox .saishi-title button {
  float: right;
  height: 35px;
  padding: 0 25px;
  cursor: pointer;
}
.container .mainBox .saishi-type {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  margin: 15px 0;
}
.container .mainBox .saishi-type .saishi-type-blue {
  width: auto;
  height: 22px;
  line-height: 22px;
  text-align: center;
  padding: 0 10px;
  color: #fff;
  font-size: 14px;
  background: #1586f7;
}
.container .mainBox .saishi-type .saishi-type-orange {
  width: auto;
  height: 22px;
  line-height: 22px;
  text-align: center;
  padding: 0 10px 0 20px;
  color: #fff;
  font-size: 14px;
  background: #f45700;
  position: relative;
  margin-left: 20px;
}
.container .mainBox .saishi-type .saishi-type-orange.yjs {
  background: gray;
  color: #fff;
}
.container .mainBox .saishi-type .saishi-type-orange.wks {
  background: #5cb85c;
  color: #fff;
}

.container .mainBox .saishi-type .saishi-type-orange::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-left: 10px solid #fff;
  border-bottom: 11px solid transparent;
  position: absolute;
  left: 0;
  top: 0;
}
.container .mainBox .saishi-time {
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  color: #4b4848;
}
.container .mainBox .saishi-proccess {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
}
.container .mainBox .saishi-proccess div {
  margin-left: 80px;
  font-size: 14px;
  color: #222121;
}
.container .mainBox .saishi-proccess div.saishi-proccess-yp {
  margin-left: 0;
}
.container .mainBox .saishi-btn-box {
  height: 55px;
  width: 670px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.container .mainBox .saishi-btn-box .saishi-countdown {
  width: auto;
  height: 32px;
  line-height: 32px;
  padding: 0 15px;
  color: #6e716f;
  font-size: 14px;
  border-radius: 4px;
  overflow: hidden;
  border: 2px solid #fbd17c;
  box-sizing: content-box;
}
.container .mainBox .saishi-btn-box .saishi-countdown span {
  color: #050505;
  font-size: 18px;
  display: inline-block;
}
.container .mainBox .saishi-btn {
  width: auto;
  height: 32px;
  line-height: 32px;
  box-sizing: content-box;
  color: #1586f7;
  border-radius: 4px;
  border: 2px solid #1b99ed;
  margin-left: 22px;
  padding: 0 12px;
  cursor: pointer;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}

.reload {
  width: auto;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  border-radius: 4px;
  background: #f45700;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
}

.container .mainBox .p-navbar-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.container .mainBox .p-navbar {
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  color: #000000;
  display: flex;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.container .mainBox .p-navbar span {
  display: inline-block;
}
.container .mainBox .p-navbar .p-bar {
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
}
.container .mainBox .p-navbar .p-bar .p-bar-item {
  width: auto;
  height: 26px;
  line-height: 26px;
  font-size: 14px;
  color: #000;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 35px;
  padding: 0 13px;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
.container .mainBox .p-navbar .p-bar .p-bar-item.active {
  background: #038eeb;
  color: #fff;
}
.container .mainBox .p-navbar .p-bar .p-bar-item:hover {
  background: #038eeb;
  color: #fff;
}

.container .mainBox .p-navbar .p-bar .search-content {
  /* height: 30px; */
  display: flex;
  /* margin-left: 35px; */
}
.container .mainBox .p-navbar .p-bar .search-content .search-inp {
  flex: 1;
  height: 28px;
  outline: none;
  background-color: transparent;
  border: 1px solid #e4e4e4;
  box-sizing: content-box;
  padding-left: 14px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 14px;
}
.container .mainBox .p-navbar .p-bar .search-content .search-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 45px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  color: #fff;
  background: #038eeb url("../../assets/stucplan/search-icon.png") no-repeat center;
}

/* 确认单列表 */
.confirmationList {
  width: 800px;
  height: 700px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 100;
  overflow: hidden;
}
.confirmationList .title {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  color: #000000;
}

.confirmationList .list-box {
  width: 750px;
  height: 560px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  overflow-y: auto;
  display: block;
}
.confirmationList .list-box img {
  width: 100%;
  height: auto;
  display: block;
}
.confirmationList .close-small-btn {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 101;
  background: url("../../assets/stucplan/close-2.png") no-repeat center;
  background-size: 50%;
  cursor: pointer;
}

.confirmationList .close-btn {
  width: 198px;
  height: 34px;
  line-height: 34px;
  background: #0795f4;
  color: #fff;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  outline: none;
  border: none;
  display: block;
  cursor: pointer;
}

.pd-list {
  width: 100%;
  height: auto;
  padding-top: 40px;
  overflow: hidden;
}
.pd-list .pd-item {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.pd-list .pd-item .pd-img {
  width: 75px;
  height: 75px;
  position: relative;
}
.pd-list .pd-item .pd-img img {
  width: 75px;
  height: 75px;
  display: block;
}
.pd-list .pd-item .pd-info {
  width: 650px;
  height: 100px;
  overflow: hidden;
  margin-left: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pd-list .pd-item .pd-info .pd-info-title {
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 18px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pd-list .pd-item .pd-info .pd-info-title .pd-info-city {
  width: auto;
  height: 20px;
  line-height: 20px;
  box-sizing: content-box;
  font-size: 14px;
  color: #000;
  margin-left: 5px;
  border: 1px solid #038eeb;
  border-radius: 4px;
  padding: 0 6px;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
.pd-list .pd-item .pd-info .pd-info-category {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pd-list .pd-item .pd-info-fraction-box {
  width: 130px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #f45700;
}
.pd-list .pd-item .pd-info-fraction-box .pd-info-fraction {
  font-size: 24px;
}
.pd-list .pd-item .pd-info-fraction-box .pd-info-fraction-p {
  font-size: 16px;
}

.pd-list .pd-item .pd-btn {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}
.pd-list .pd-item .pd-btn a {
  width: auto;
  height: 34px;
  line-height: 34px;
  padding: 0 13px;
  background: #fff;
  border: 1px solid #038eeb;
  color: #038eeb;
  border-radius: 4px;
}

.page-pag {
  width: 100%;
  height: 34px;
  line-height: 34px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
}
.page-pag div {
  display: inline-block;
}
.page-pag .count {
  margin-left: 20px;
}
.page-pag .totals div {
  width: auto;
  height: 34px;
  line-height: 34px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ddd;
  color: #3096ea;
  padding: 0 13px;
}
.page-pag .prev-btn,
.page-pag .next-btn {
  width: auto;
  height: 34px;
  line-height: 34px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ddd;
  color: #3096ea;
  padding: 0 13px;
}

.page-pag .totals div.active {
  background: #3096ea;
  border-color: #3096ea;
  color: #fff;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
.page-pag .totals div:hover,
.page-pag .prev-btn:hover,
.page-pag .next-btn:hover {
  background: #3096ea;
  border-color: #3096ea;
  color: #fff;
}
button:focus {
  outline: none;
}

.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(114, 114, 114, 0.8);
  z-index: 99;
}

.rules {
  width: 720px;
  height: 570px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 100;
  background: #fff;
}
.rules .rule-title {
  width: 100%;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 18px;
  color: #000;
  font-weight: 600;
}
.rules .rule-content {
  width: 620px;
  height: 400px;
  overflow: hidden;
  overflow-y: auto;
  background: #d8d8d8;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}
.rules .rule-tips {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 12px;
  color: #9ea0a1;
}
.rules .rule-confirm {
  width: 200px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  background: #038eeb;
  font-size: 14px;
  border-radius: 4px;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
  cursor: pointer;
}

.confirmation {
  width: 654px;
  height: 467px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 100;
  background: #fff;
}
.confirmation .close-btn {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 101;
  background: url("../../assets/stucplan/close-2.png") no-repeat center;
  background-size: 50%;
  cursor: pointer;
}
.confirmation .confirmation-title {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  color: #000000;
}
.confirmation .confirmation-content {
  width: 550px;
  height: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.confirmation .confirmation-content .box {
  height: 230px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-width: 300px;
}
.confirmation .confirmation-content .box .name {
  font-size: 14px;
  color: #000000;
}
.confirmation .confirmation-content .box .name .num {
  width: 26px;
  height: 26px;
  line-height: 26px;
  border-radius: 50%;
  border: 1px solid #0c92ec;
  color: #000;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
}
.confirmation .confirmation-content .hr {
  height: 82px;
  border-right: 1px dashed #000;
  margin-right: 30px;
}
.confirmation .confirmation-content .box .icon1 {
  width: 26px;
  height: 33px;
  background: url("../../assets/stucplan/ps-pic1.png") no-repeat;
  background-size: contain;
  margin: 0 auto;
  margin-top: 40px;
}
.confirmation .confirmation-content .box .download-btn {
  width: auto;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0 20px;
  border: 1px solid #bfc1c2;
  color: #000;
  border-radius: 4px;
  cursor: pointer;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
  cursor: pointer;
  margin-top: 30px;
}
.confirmation .confirmation-content .box .icon2 {
  width: 26px;
  height: 33px;
  background: url("../../assets/stucplan/ps-pic2.png") no-repeat;
  background-size: contain;
  margin: 0 auto;
  margin-top: 40px;
}
.confirmation .confirmation-content .box .form-box {
  display: flex;
  margin-top: 30px;
  width: 100%;
  text-align: center;
}
.confirmation .confirmation-content .box .form-box .reset {
  width: auto;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0 20px;
  background: gray;
  border: 1px solid #bfc1c2;
  color: #fff;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
  display: none;
}
.confirmation .confirmation-content .box .upload-btn {
  display: block;
  position: relative;
  width: auto;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0 20px;
  background: #0c92ec;
  border: 1px solid #bfc1c2;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
  cursor: pointer;
}
.confirmation .confirmation-content .box .upload-btn input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.confirmation .confirmation-btn {
  width: 198px;
  margin: 0 auto;
  margin-top: 20px;
  display: block;
}

.visaDetailTop p span {
  color: #fff;
  font-size: 1.2em;
}
.visaDetailTop p:first-of-type {
  float: left;
}
.visaDetailTop p:nth-of-type(2) {
  float: right;
}
.canvasBox {
  /* padding: 10px 5px; */
  box-sizing: border-box;
  flex: 1;
}
canvas {
  border: 1px solid #ccc;
  width: 95%;
}
.btnBox {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  box-sizing: border-box;
  /* height: 30px; */
  /* padding: 5px; */
  text-align: right;
  /* line-height: 30px; */
  padding-top: 10px;
  padding-right: 80px;
  padding-bottom: 30px;
}
.btnBox button {
  font-size: 24px;
}
.btnBox button.white {
  border: 1px solid #1989fa;
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  background: #fff;
  cursor: pointer;
  margin-left: 1rem;
}
.btnBox button.blue {
  border: 1px solid #1989fa;
  background: #1989fa;
  color: #fff;
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  cursor: pointer;
}
.p-title {
  font-size: 24px;
  padding: 20px 0 10px 15px;
}
.signatureBox {
  cursor: crosshair;
}
.contTitle {
  width: 100%;
  text-align: center;
  /* height: 50px; */
  line-height: 50px;
  font-size: 30px;
  font-weight: bold;
  padding: 30px 0;
}
/* ---- 移动端start --- */
.xs_main {
  width: 100%;
  height: auto;
  /* padding: 0 .2rem; */
  box-sizing: border-box;
}

.xs_ps_wrap {
  background: #e8edf3;
  padding-bottom: 0.5rem;
}

.xs_ps_title {
  font-size: 0.4rem;
  color: #fff;
  padding: 0.3rem 0.2rem 0;
  height: 2rem;
  /* padding-bottom: .2rem; */
  background: url(../../assets/stucplan/pingshen-bg.png) no-repeat top;
  background-size: 100% 100%;
}

.xs_ps_main {
  background: #fff;
  /* padding: 0.3rem; */
  margin: 0 0.2rem;
  margin-top: -1rem;
  padding-bottom: 0.4rem;
}
.data_table {
  padding: 0.2rem 0.4rem;
}
.mytable_header {
  font-size: 0.4rem;
  color: #000;
  display: flex;
  text-align: center;
  font-weight: 700;
}
.mytable_header > div {
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #eee;
  border-left: 1px solid #f1f1f1;
  padding: 0.2rem 0;
  font-size: 0.4rem;
}
.mytable_header > div:last-child {
  border-right: 1px solid #eee;
}
.my_table_body {
  font-size: 0.4rem;
  display: flex;
  text-align: center;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
}
.my_table_body > div {
  /* margin: .2rem 0; */
  padding: 0.2rem 0;
  border-left: 1px solid #f1f1f1;
  font-size: 0.4rem;
}
.my_table_body > div:last-child {
  border-right: 1px solid #f1f1f1;
}
.xs {
  flex: 0.4;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.sm {
  flex: 1;
  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
}
.md {
  flex: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.lg {
  flex: 3;
}
.xs_qming_img_content {
  background: #f2f2f2;
  padding: 0.4rem;
  margin: 0.4rem;
  box-sizing: border-box;
  height: 8rem;
}
.xs_qming_view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit_box {
  display: flex;
  align-items: center;
}
.xs_confirm_btn_group {
  text-align: right;
  margin: 0.5rem 0.4rem 0.2rem;
}
.xs_queren_title {
  text-align: center;
  font-weight: 700;
  font-size: 0.4rem;
  /* margin: .5rem 0 */
}
.xs_ps_main /deep/ .van-popup--center {
  z-index: 99999999999 !important;
}
/* ---- 移动端end --- */
</style>